(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports'], factory);
  else if (typeof exports === 'object')
    factory(module.exports);
  else
    root['kotlin-cv'] = factory(typeof this['kotlin-cv'] === 'undefined' ? {} : this['kotlin-cv']);
}(this, function (_) {
  'use strict';
  AbstractSet.prototype = Object.create(AbstractCollection.prototype);
  AbstractSet.prototype.constructor = AbstractSet;
  _no_name_provided__2.prototype = Object.create(AbstractSet.prototype);
  _no_name_provided__2.prototype.constructor = _no_name_provided__2;
  IntProgressionIterator.prototype = Object.create(IntIterator.prototype);
  IntProgressionIterator.prototype.constructor = IntProgressionIterator;
  IntRange.prototype = Object.create(IntProgression.prototype);
  IntRange.prototype.constructor = IntRange;
  AbstractMutableCollection.prototype = Object.create(AbstractCollection.prototype);
  AbstractMutableCollection.prototype.constructor = AbstractMutableCollection;
  ListIteratorImpl.prototype = Object.create(IteratorImpl.prototype);
  ListIteratorImpl.prototype.constructor = ListIteratorImpl;
  AbstractMutableList.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableList.prototype.constructor = AbstractMutableList;
  AbstractMutableSet.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableSet.prototype.constructor = AbstractMutableSet;
  AbstractEntrySet.prototype = Object.create(AbstractMutableSet.prototype);
  AbstractEntrySet.prototype.constructor = AbstractEntrySet;
  _no_name_provided__13.prototype = Object.create(AbstractMutableSet.prototype);
  _no_name_provided__13.prototype.constructor = _no_name_provided__13;
  AbstractMutableMap.prototype = Object.create(AbstractMap.prototype);
  AbstractMutableMap.prototype.constructor = AbstractMutableMap;
  ArrayList.prototype = Object.create(AbstractMutableList.prototype);
  ArrayList.prototype.constructor = ArrayList;
  EntrySet.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet.prototype.constructor = EntrySet;
  HashMap.prototype = Object.create(AbstractMutableMap.prototype);
  HashMap.prototype.constructor = HashMap;
  HashSet.prototype = Object.create(AbstractMutableSet.prototype);
  HashSet.prototype.constructor = HashSet;
  ChainEntry.prototype = Object.create(SimpleEntry.prototype);
  ChainEntry.prototype.constructor = ChainEntry;
  EntrySet_0.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet_0.prototype.constructor = EntrySet_0;
  LinkedHashMap.prototype = Object.create(HashMap.prototype);
  LinkedHashMap.prototype.constructor = LinkedHashMap;
  LinkedHashSet.prototype = Object.create(HashSet.prototype);
  LinkedHashSet.prototype.constructor = LinkedHashSet;
  PrimitiveKClassImpl.prototype = Object.create(KClassImpl.prototype);
  PrimitiveKClassImpl.prototype.constructor = PrimitiveKClassImpl;
  NothingKClassImpl.prototype = Object.create(KClassImpl.prototype);
  NothingKClassImpl.prototype.constructor = NothingKClassImpl;
  SimpleKClassImpl.prototype = Object.create(KClassImpl.prototype);
  SimpleKClassImpl.prototype.constructor = SimpleKClassImpl;
  RegexOption.prototype = Object.create(Enum.prototype);
  RegexOption.prototype.constructor = RegexOption;
  _no_name_provided__39.prototype = Object.create(AbstractCollection.prototype);
  _no_name_provided__39.prototype.constructor = _no_name_provided__39;
  Long.prototype = Object.create(Number_0.prototype);
  Long.prototype.constructor = Long;
  Exception.prototype = Object.create(Error.prototype);
  Exception.prototype.constructor = Exception;
  RuntimeException.prototype = Object.create(Exception.prototype);
  RuntimeException.prototype.constructor = RuntimeException;
  IllegalArgumentException.prototype = Object.create(RuntimeException.prototype);
  IllegalArgumentException.prototype.constructor = IllegalArgumentException;
  NoSuchElementException.prototype = Object.create(RuntimeException.prototype);
  NoSuchElementException.prototype.constructor = NoSuchElementException;
  IllegalStateException.prototype = Object.create(RuntimeException.prototype);
  IllegalStateException.prototype.constructor = IllegalStateException;
  IndexOutOfBoundsException.prototype = Object.create(RuntimeException.prototype);
  IndexOutOfBoundsException.prototype.constructor = IndexOutOfBoundsException;
  UnsupportedOperationException.prototype = Object.create(RuntimeException.prototype);
  UnsupportedOperationException.prototype.constructor = UnsupportedOperationException;
  ArithmeticException.prototype = Object.create(RuntimeException.prototype);
  ArithmeticException.prototype.constructor = ArithmeticException;
  NullPointerException.prototype = Object.create(RuntimeException.prototype);
  NullPointerException.prototype.constructor = NullPointerException;
  ClassCastException.prototype = Object.create(RuntimeException.prototype);
  ClassCastException.prototype.constructor = ClassCastException;
  StringAttribute.prototype = Object.create(Attribute.prototype);
  StringAttribute.prototype.constructor = StringAttribute;
  DIV.prototype = Object.create(HTMLTag.prototype);
  DIV.prototype.constructor = DIV;
  PRE.prototype = Object.create(HTMLTag.prototype);
  PRE.prototype.constructor = PRE;
  function indexOf(_this_, element) {
    if (element == null) {
      var inductionVariable = 0;
      var last_1 = _this_.length - 1 | 0;
      if (inductionVariable <= last_1)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (_this_[index] == null) {
            return index;
          }}
         while (inductionVariable <= last_1);
    } else {
      var inductionVariable_0 = 0;
      var last_2 = _this_.length - 1 | 0;
      if (inductionVariable_0 <= last_2)
        do {
          var index_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (equals_0(element, _this_[index_0])) {
            return index_0;
          }}
         while (inductionVariable_0 <= last_2);
    }
    return -1;
  }
  function toCollection(_this_, destination) {
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    while (inductionVariable < last_1) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      destination.add_16(item);
      Unit_getInstance();
    }
    return destination;
  }
  function contains(_this_, element) {
    return indexOf(_this_, element) >= 0;
  }
  function joinToString(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo(_this_, StringBuilder_init_$Create$_0(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function joinTo(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count = 0;
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    $l$break: while (inductionVariable < last_1) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      count = count + 1 | 0;
      if (count > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo_0(_this_, StringBuilder_init_$Create$_0(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default_0(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function toSet(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__32();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptySet();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_30(0);
          } else {
            {
              tmp_0 = _this_.iterator_40().next_16();
            }
          }

          tmp = setOf(tmp_0);
          break;
        default:tmp = toCollection_0(_this_, LinkedHashSet_init_$Create$_0(mapCapacity(_this_._get_size__32())));
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlySet(toCollection_0(_this_, LinkedHashSet_init_$Create$()));
  }
  function first(_this_) {
    if (_this_.isEmpty_28())
      throw NoSuchElementException_init_$Create$_0('List is empty.');
    return _this_.get_30(0);
  }
  function drop(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }if (n === 0)
      return toList(_this_);
    var list;
    if (isInterface(_this_, Collection)) {
      var resultSize = _this_._get_size__32() - n | 0;
      if (resultSize <= 0)
        return emptyList();
      if (resultSize === 1)
        return listOf_0(last(_this_));
      list = ArrayList_init_$Create$_0(resultSize);
      if (isInterface(_this_, List)) {
        if (isInterface(_this_, RandomAccess)) {
          var inductionVariable = n;
          var last_1 = _this_._get_size__32();
          if (inductionVariable < last_1)
            do {
              var index = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              list.add_16(_this_.get_30(index));
              Unit_getInstance();
            }
             while (inductionVariable < last_1);
        } else {
          {
            var tmp1_iterator_0 = _this_.listIterator_2(n);
            var tmp1_iterator = tmp1_iterator_0;
            while (tmp1_iterator.hasNext_14()) {
              var item = tmp1_iterator.next_16();
              list.add_16(item);
              Unit_getInstance();
            }
          }
        }
        return list;
      } else {
      }
    } else {
      {
        list = ArrayList_init_$Create$();
      }
    }
    var count = 0;
    var tmp2_iterator = _this_.iterator_40();
    while (tmp2_iterator.hasNext_14()) {
      var item_0 = tmp2_iterator.next_16();
      if (count >= n) {
        list.add_16(item_0);
        Unit_getInstance();
      } else {
        count = count + 1 | 0;
        Unit_getInstance();
      }
    }
    return optimizeReadOnlyList(list);
  }
  function toList(_this_) {
    if (isInterface(_this_, Collection)) {
      var tmp0_subject = _this_._get_size__32();
      var tmp;
      switch (tmp0_subject) {
        case 0:
          tmp = emptyList();
          break;
        case 1:
          var tmp_0;
          if (isInterface(_this_, List)) {
            tmp_0 = _this_.get_30(0);
          } else {
            {
              tmp_0 = _this_.iterator_40().next_16();
            }
          }

          tmp = listOf_0(tmp_0);
          break;
        default:tmp = toMutableList(_this_);
          break;
      }
      return tmp;
    } else {
    }
    return optimizeReadOnlyList(toMutableList_0(_this_));
  }
  function joinTo_0(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_3(prefix);
    Unit_getInstance();
    var count = 0;
    var tmp0_iterator = _this_.iterator_40();
    $l$break: while (tmp0_iterator.hasNext_14()) {
      var element = tmp0_iterator.next_16();
      count = count + 1 | 0;
      if (count > 1) {
        buffer.append_3(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.append_3(truncated);
      Unit_getInstance();
    }buffer.append_3(postfix);
    Unit_getInstance();
    return buffer;
  }
  function joinTo$default(_this_, buffer, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      separator = ', ';
    if (!(($mask0 & 4) === 0))
      prefix = '';
    if (!(($mask0 & 8) === 0))
      postfix = '';
    if (!(($mask0 & 16) === 0))
      limit = -1;
    if (!(($mask0 & 32) === 0))
      truncated = '...';
    if (!(($mask0 & 64) === 0))
      transform = null;
    return joinTo_0(_this_, buffer, separator, prefix, postfix, limit, truncated, transform);
  }
  function toCollection_0(_this_, destination) {
    var tmp0_iterator = _this_.iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var item = tmp0_iterator.next_16();
      destination.add_16(item);
      Unit_getInstance();
    }
    return destination;
  }
  function last(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List))
      return last_0(_this_);
    else {
      {
        var iterator = _this_.iterator_40();
        if (!iterator.hasNext_14())
          throw NoSuchElementException_init_$Create$_0('Collection is empty.');
        var last_1 = iterator.next_16();
        while (iterator.hasNext_14())
          last_1 = iterator.next_16();
        return last_1;
      }
    }
  }
  function toMutableList(_this_) {
    return ArrayList_init_$Create$_1(_this_);
  }
  function toMutableList_0(_this_) {
    if (isInterface(_this_, Collection))
      return toMutableList(_this_);
    else {
    }
    return toCollection_0(_this_, ArrayList_init_$Create$());
  }
  function last_0(_this_) {
    if (_this_.isEmpty_28())
      throw NoSuchElementException_init_$Create$_0('List is empty.');
    return _this_.get_30(_get_lastIndex_(_this_));
  }
  function asSequence(_this_) {
    return new _no_name_provided__1(_this_);
  }
  function minOrNull(_this_) {
    var iterator = _this_.iterator_40();
    if (!iterator.hasNext_14())
      return null;
    var min = iterator.next_16();
    while (iterator.hasNext_14()) {
      var e = iterator.next_16();
      if (compareTo(min, e) > 0)
        min = e;
    }
    return min;
  }
  function single(_this_) {
    var tmp0_subject = _this_;
    if (isInterface(tmp0_subject, List))
      return single_0(_this_);
    else {
      {
        var iterator = _this_.iterator_40();
        if (!iterator.hasNext_14())
          throw NoSuchElementException_init_$Create$_0('Collection is empty.');
        var single_1 = iterator.next_16();
        if (iterator.hasNext_14())
          throw IllegalArgumentException_init_$Create$('Collection has more than one element.');
        return single_1;
      }
    }
  }
  function single_0(_this_) {
    var tmp0_subject = _this_._get_size__32();
    var tmp;
    switch (tmp0_subject) {
      case 0:
        throw NoSuchElementException_init_$Create$_0('List is empty.');
      case 1:
        tmp = _this_.get_30(0);
        break;
      default:throw IllegalArgumentException_init_$Create$('List has more than one element.');
    }
    return tmp;
  }
  function _no_name_provided__1($this_asSequence) {
    this._$this_asSequence = $this_asSequence;
  }
  _no_name_provided__1.prototype.iterator_2 = function () {
    return this._$this_asSequence.iterator_40();
  };
  _no_name_provided__1.prototype.iterator_40 = function () {
    return this.iterator_2();
  };
  _no_name_provided__1.$metadata$ = {
    simpleName: '<no name provided>_1',
    kind: 'class',
    interfaces: [Sequence]
  };
  function coerceAtLeast(_this_, minimumValue) {
    return _this_ < minimumValue ? minimumValue : _this_;
  }
  function until(_this_, to_0) {
    if (to_0 <= IntCompanionObject_getInstance()._MIN_VALUE)
      return Companion_getInstance_3()._EMPTY;
    return numberRangeToNumber(_this_, to_0 - 1 | 0);
  }
  function coerceAtMost(_this_, maximumValue) {
    return _this_ > maximumValue ? maximumValue : _this_;
  }
  function downTo(_this_, to_0) {
    return Companion_getInstance_2().fromClosedRange(_this_, to_0, -1);
  }
  function coerceIn(_this_, minimumValue, maximumValue) {
    if (minimumValue > maximumValue)
      throw IllegalArgumentException_init_$Create$('' + 'Cannot coerce value to an empty range: maximum ' + maximumValue + ' is less than minimum ' + minimumValue + '.');
    if (_this_ < minimumValue)
      return minimumValue;
    if (_this_ > maximumValue)
      return maximumValue;
    return _this_;
  }
  function take(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested element count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }var tmp;
    if (n === 0) {
      tmp = emptySequence();
    } else {
      if (isInterface(_this_, DropTakeSequence)) {
        tmp = _this_.take_1(n);
      } else {
        {
          tmp = new TakeSequence(_this_, n);
        }
      }
    }
    return tmp;
  }
  function map(_this_, transform) {
    return new TransformingSequence(_this_, transform);
  }
  function toList_0(_this_) {
    return optimizeReadOnlyList(toMutableList_1(_this_));
  }
  function toMutableList_1(_this_) {
    return toCollection_1(_this_, ArrayList_init_$Create$());
  }
  function toCollection_1(_this_, destination) {
    var tmp0_iterator = _this_.iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var item = tmp0_iterator.next_16();
      destination.add_16(item);
      Unit_getInstance();
    }
    return destination;
  }
  function dropLast(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }return take_0(_this_, coerceAtLeast(_this_.length - n | 0, 0));
  }
  function take_0(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }var tmp1_substring_0 = 0;
    var tmp2_substring_0 = coerceAtMost(n, _this_.length);
    return _this_.substring(tmp1_substring_0, tmp2_substring_0);
  }
  function drop_0(_this_, n) {
    var tmp0_require_0 = n >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Requested character count ' + n + ' is less than zero.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }var tmp1_substring_0 = coerceAtMost(n, _this_.length);
    return _this_.substring(tmp1_substring_0);
  }
  function _no_name_provided_(this$0) {
    this._this$0 = this$0;
  }
  _no_name_provided_.prototype.invoke = function (it) {
    return it === this._this$0 ? '(this Collection)' : toString_0(it);
  };
  _no_name_provided_.prototype.invoke_84 = function (p1) {
    return this.invoke((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided_.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractCollection() {
  }
  AbstractCollection.prototype.contains_19 = function (element) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(this, Collection)) {
        tmp = this.isEmpty_28();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = this.iterator_40();
      while (tmp0_iterator_1.hasNext_14()) {
        var element_2 = tmp0_iterator_1.next_16();
        if (equals_0(element_2, element)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.containsAll_19 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_28();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_40();
      while (tmp0_iterator_1.hasNext_14()) {
        var element_2 = tmp0_iterator_1.next_16();
        if (!this.contains_19(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.isEmpty_28 = function () {
    return this._get_size__32() === 0;
  };
  AbstractCollection.prototype.toString = function () {
    return joinToString$default_0(this, ', ', '[', ']', 0, null, _no_name_provided_$factory(this), 24, null);
  };
  AbstractCollection.prototype.toArray = function () {
    return copyToArrayImpl_0(this);
  };
  AbstractCollection.$metadata$ = {
    simpleName: 'AbstractCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function _no_name_provided_$factory(this$0) {
    var i = new _no_name_provided_(this$0);
    return function (p1) {
      return i.invoke(p1);
    };
  }
  function Companion_0() {
    Companion_instance = this;
  }
  Companion_0.prototype.checkElementIndex = function (index, size) {
    if (index < 0 ? true : index >= size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size);
    }};
  Companion_0.prototype.checkPositionIndex = function (index, size) {
    if (index < 0 ? true : index > size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size);
    }};
  Companion_0.prototype.orderedHashCode = function (c) {
    var hashCode_1 = 1;
    var tmp0_iterator = c.iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var e = tmp0_iterator.next_16();
      var tmp = imul(31, hashCode_1);
      var tmp1_safe_receiver = e;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_1 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_1;
  };
  Companion_0.prototype.orderedEquals = function (c, other) {
    if (!(c._get_size__32() === other._get_size__32()))
      return false;
    var otherIterator = other.iterator_40();
    var tmp0_iterator = c.iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var elem = tmp0_iterator.next_16();
      var elemOther = otherIterator.next_16();
      if (!equals_0(elem, elemOther)) {
        return false;
      }}
    return true;
  };
  Companion_0.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion_0();
    return Companion_instance;
  }
  function _no_name_provided__0($entryIterator) {
    this._$entryIterator = $entryIterator;
  }
  _no_name_provided__0.prototype.hasNext_14 = function () {
    return this._$entryIterator.hasNext_14();
  };
  _no_name_provided__0.prototype.next_16 = function () {
    return this._$entryIterator.next_16()._get_key__7();
  };
  _no_name_provided__0.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  function toString($this, o) {
    return o === $this ? '(this Map)' : toString_0(o);
  }
  function implFindEntry($this, key) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_firstOrNull_0 = $this._get_entries__7();
      var tmp0_iterator_1 = tmp0_firstOrNull_0.iterator_40();
      while (tmp0_iterator_1.hasNext_14()) {
        var element_2 = tmp0_iterator_1.next_16();
        if (equals_0(element_2._get_key__7(), key)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function Companion_1() {
    Companion_instance_0 = this;
  }
  Companion_1.prototype.entryHashCode = function (e) {
    var tmp2_safe_receiver_4 = e._get_key__7();
    var tmp3_elvis_lhs_3 = tmp2_safe_receiver_4 == null ? null : hashCode(tmp2_safe_receiver_4);
    var tmp = tmp3_elvis_lhs_3 == null ? 0 : tmp3_elvis_lhs_3;
    var tmp0_safe_receiver_6 = e._get_value__8();
    var tmp1_elvis_lhs_5 = tmp0_safe_receiver_6 == null ? null : hashCode(tmp0_safe_receiver_6);
    return tmp ^ (tmp1_elvis_lhs_5 == null ? 0 : tmp1_elvis_lhs_5);
  };
  Companion_1.prototype.entryToString = function (e) {
    return '' + e._get_key__7() + '=' + e._get_value__8();
  };
  Companion_1.prototype.entryEquals = function (e, other) {
    if (!(!(other == null) ? isInterface(other, Entry) : false))
      return false;
    else {
    }
    return equals_0(e._get_key__7(), other._get_key__7()) ? equals_0(e._get_value__8(), other._get_value__8()) : false;
  };
  Companion_1.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_1();
    return Companion_instance_0;
  }
  function _no_name_provided__2(this$0) {
    this._this$0_0 = this$0;
    AbstractSet.call(this);
  }
  _no_name_provided__2.prototype.contains_15 = function (element) {
    return this._this$0_0.containsKey_9(element);
  };
  _no_name_provided__2.prototype.contains_19 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_15((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__2.prototype.iterator_40 = function () {
    var entryIterator = this._this$0_0._get_entries__7().iterator_40();
    return new _no_name_provided__0(entryIterator);
  };
  _no_name_provided__2.prototype._get_size__32 = function () {
    return this._this$0_0._get_size__32();
  };
  _no_name_provided__2.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__3(this$0) {
    this._this$0_1 = this$0;
  }
  _no_name_provided__3.prototype.invoke_1 = function (it) {
    return this._this$0_1.toString_0(it);
  };
  _no_name_provided__3.prototype.invoke_84 = function (p1) {
    return this.invoke_1((!(p1 == null) ? isInterface(p1, Entry) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__3.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMap() {
    Companion_getInstance_0();
    this.__keys = null;
    this.__values = null;
  }
  AbstractMap.prototype.containsKey_9 = function (key) {
    return !(implFindEntry(this, key) == null);
  };
  AbstractMap.prototype.containsEntry_5 = function (entry) {
    if (!(!(entry == null) ? isInterface(entry, Entry) : false))
      return false;
    else {
    }
    var key = entry._get_key__7();
    var value = entry._get_value__8();
    var ourValue = (isInterface(this, Map_0) ? this : THROW_CCE()).get_20(key);
    if (!equals_0(value, ourValue)) {
      return false;
    }var tmp;
    if (ourValue == null) {
      tmp = !(isInterface(this, Map_0) ? this : THROW_CCE()).containsKey_9(key);
    } else {
      tmp = false;
    }
    if (tmp) {
      return false;
    } else {
    }
    return true;
  };
  AbstractMap.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Map_0) : false))
      return false;
    else {
    }
    if (!(this._get_size__32() === other._get_size__32()))
      return false;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = other._get_entries__7();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_28();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_40();
      while (tmp0_iterator_1.hasNext_14()) {
        var element_2 = tmp0_iterator_1.next_16();
        if (!this.containsEntry_5(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractMap.prototype.get_20 = function (key) {
    var tmp0_safe_receiver = implFindEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__8();
  };
  AbstractMap.prototype.hashCode = function () {
    return hashCode(this._get_entries__7());
  };
  AbstractMap.prototype.isEmpty_28 = function () {
    return this._get_size__32() === 0;
  };
  AbstractMap.prototype._get_size__32 = function () {
    return this._get_entries__7()._get_size__32();
  };
  AbstractMap.prototype._get_keys__7 = function () {
    if (this.__keys == null) {
      var tmp = this;
      tmp.__keys = new _no_name_provided__2(this);
    }return ensureNotNull(this.__keys);
  };
  AbstractMap.prototype.toString = function () {
    var tmp = this._get_entries__7();
    return joinToString$default_0(tmp, ', ', '{', '}', 0, null, _no_name_provided_$factory_0(this), 24, null);
  };
  AbstractMap.prototype.toString_0 = function (entry) {
    return toString(this, entry._get_key__7()) + '=' + toString(this, entry._get_value__8());
  };
  AbstractMap.$metadata$ = {
    simpleName: 'AbstractMap',
    kind: 'class',
    interfaces: [Map_0]
  };
  function _no_name_provided_$factory_0(this$0) {
    var i = new _no_name_provided__3(this$0);
    return function (p1) {
      return i.invoke_1(p1);
    };
  }
  function Companion_2() {
    Companion_instance_1 = this;
  }
  Companion_2.prototype.unorderedHashCode = function (c) {
    var hashCode_1 = 0;
    var tmp0_iterator = c.iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var element = tmp0_iterator.next_16();
      var tmp = hashCode_1;
      var tmp1_safe_receiver = element;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_1 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_1;
  };
  Companion_2.prototype.setEquals = function (c, other) {
    if (!(c._get_size__32() === other._get_size__32()))
      return false;
    return c.containsAll_19(other);
  };
  Companion_2.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_2();
    return Companion_instance_1;
  }
  function AbstractSet() {
    Companion_getInstance_1();
    AbstractCollection.call(this);
  }
  AbstractSet.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Set) : false))
      return false;
    else {
    }
    return Companion_getInstance_1().setEquals(this, other);
  };
  AbstractSet.prototype.hashCode = function () {
    return Companion_getInstance_1().unorderedHashCode(this);
  };
  AbstractSet.$metadata$ = {
    simpleName: 'AbstractSet',
    kind: 'class',
    interfaces: [Set]
  };
  function _get_indices_(_this_) {
    return numberRangeToNumber(0, _this_._get_size__32() - 1 | 0);
  }
  function listOf(elements) {
    return elements.length > 0 ? asList(elements) : emptyList();
  }
  function EmptyList() {
    EmptyList_instance = this;
    this._serialVersionUID = new Long(-1478467534, -1720727600);
  }
  EmptyList.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, List) : false) {
      tmp = other.isEmpty_28();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptyList.prototype.hashCode = function () {
    return 1;
  };
  EmptyList.prototype.toString = function () {
    return '[]';
  };
  EmptyList.prototype._get_size__32 = function () {
    return 0;
  };
  EmptyList.prototype.isEmpty_28 = function () {
    return true;
  };
  EmptyList.prototype.containsAll_6 = function (elements) {
    return elements.isEmpty_28();
  };
  EmptyList.prototype.containsAll_19 = function (elements) {
    return this.containsAll_6(elements);
  };
  EmptyList.prototype.get_30 = function (index) {
    throw IndexOutOfBoundsException_init_$Create$('' + "Empty list doesn't contain element at index " + index + '.');
  };
  EmptyList.prototype.iterator_40 = function () {
    return EmptyIterator_getInstance();
  };
  EmptyList.prototype.listIterator_2 = function (index) {
    if (!(index === 0))
      throw IndexOutOfBoundsException_init_$Create$('' + 'Index: ' + index);
    return EmptyIterator_getInstance();
  };
  EmptyList.$metadata$ = {
    simpleName: 'EmptyList',
    kind: 'object',
    interfaces: [List, Serializable, RandomAccess]
  };
  var EmptyList_instance;
  function EmptyList_getInstance() {
    if (EmptyList_instance == null)
      new EmptyList();
    return EmptyList_instance;
  }
  function emptyList() {
    return EmptyList_getInstance();
  }
  function optimizeReadOnlyList(_this_) {
    var tmp0_subject = _this_._get_size__32();
    switch (tmp0_subject) {
      case 0:
        return emptyList();
      case 1:
        return listOf_0(_this_.get_30(0));
      default:return _this_;
    }
  }
  function _get_lastIndex_(_this_) {
    return _this_._get_size__32() - 1 | 0;
  }
  function EmptyIterator() {
    EmptyIterator_instance = this;
  }
  EmptyIterator.prototype.hasNext_14 = function () {
    return false;
  };
  EmptyIterator.prototype.next_16 = function () {
    throw NoSuchElementException_init_$Create$();
  };
  EmptyIterator.$metadata$ = {
    simpleName: 'EmptyIterator',
    kind: 'object',
    interfaces: [ListIterator]
  };
  var EmptyIterator_instance;
  function EmptyIterator_getInstance() {
    if (EmptyIterator_instance == null)
      new EmptyIterator();
    return EmptyIterator_instance;
  }
  function arrayListOf(elements) {
    return elements.length === 0 ? ArrayList_init_$Create$() : ArrayList_init_$Create$_1(new ArrayAsCollection(elements, true));
  }
  function throwIndexOverflow() {
    throw ArithmeticException_init_$Create$('Index overflow has happened.');
  }
  function ArrayAsCollection(values, isVarargs) {
    this._values = values;
    this._isVarargs = isVarargs;
  }
  ArrayAsCollection.prototype._get_size__32 = function () {
    return this._values.length;
  };
  ArrayAsCollection.prototype.isEmpty_28 = function () {
    var tmp0_isEmpty_0 = this._values;
    return tmp0_isEmpty_0.length === 0;
  };
  ArrayAsCollection.prototype.contains_4 = function (element) {
    return contains(this._values, element);
  };
  ArrayAsCollection.prototype.containsAll_4 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_28();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_40();
      while (tmp0_iterator_1.hasNext_14()) {
        var element_2 = tmp0_iterator_1.next_16();
        if (!this.contains_4(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  ArrayAsCollection.prototype.containsAll_19 = function (elements) {
    return this.containsAll_4(elements);
  };
  ArrayAsCollection.prototype.iterator_40 = function () {
    return arrayIterator(this._values);
  };
  ArrayAsCollection.$metadata$ = {
    simpleName: 'ArrayAsCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function collectionSizeOrDefault(_this_, default_0) {
    var tmp;
    if (isInterface(_this_, Collection)) {
      tmp = _this_._get_size__32();
    } else {
      {
        tmp = default_0;
      }
    }
    return tmp;
  }
  function emptyMap() {
    var tmp = EmptyMap_getInstance();
    return isInterface(tmp, Map_0) ? tmp : THROW_CCE();
  }
  function mapOf(pairs) {
    return pairs.length > 0 ? toMap(pairs, LinkedHashMap_init_$Create$_1(mapCapacity(pairs.length))) : emptyMap();
  }
  function EmptyMap() {
    EmptyMap_instance = this;
    this._serialVersionUID_0 = new Long(-888910638, 1920087921);
  }
  EmptyMap.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, Map_0) : false) {
      tmp = other.isEmpty_28();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptyMap.prototype.hashCode = function () {
    return 0;
  };
  EmptyMap.prototype.toString = function () {
    return '{}';
  };
  EmptyMap.prototype._get_size__32 = function () {
    return 0;
  };
  EmptyMap.prototype.isEmpty_28 = function () {
    return true;
  };
  EmptyMap.prototype.containsKey_0 = function (key) {
    return false;
  };
  EmptyMap.prototype.containsKey_9 = function (key) {
    if (!(key == null ? true : isObject(key)))
      return false;
    else {
    }
    return this.containsKey_0((key == null ? true : isObject(key)) ? key : THROW_CCE());
  };
  EmptyMap.prototype.get_2 = function (key) {
    return null;
  };
  EmptyMap.prototype.get_20 = function (key) {
    if (!(key == null ? true : isObject(key)))
      return null;
    else {
    }
    return this.get_2((key == null ? true : isObject(key)) ? key : THROW_CCE());
  };
  EmptyMap.prototype._get_entries__7 = function () {
    return EmptySet_getInstance();
  };
  EmptyMap.prototype._get_keys__7 = function () {
    return EmptySet_getInstance();
  };
  EmptyMap.$metadata$ = {
    simpleName: 'EmptyMap',
    kind: 'object',
    interfaces: [Map_0, Serializable]
  };
  var EmptyMap_instance;
  function EmptyMap_getInstance() {
    if (EmptyMap_instance == null)
      new EmptyMap();
    return EmptyMap_instance;
  }
  function toMap(_this_, destination) {
    putAll(destination, _this_);
    return destination;
  }
  function putAll(_this_, pairs) {
    var indexedObject = pairs;
    var inductionVariable = 0;
    var last_1 = indexedObject.length;
    while (inductionVariable < last_1) {
      var tmp1_loop_parameter = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var key = tmp1_loop_parameter.component1();
      var value = tmp1_loop_parameter.component2();
      _this_.put_7(key, value);
      Unit_getInstance();
    }
  }
  function Sequence() {
  }
  Sequence.$metadata$ = {
    simpleName: 'Sequence',
    kind: 'interface',
    interfaces: []
  };
  function generateSequence(seedFunction, nextFunction) {
    return new GeneratorSequence(seedFunction, nextFunction);
  }
  function calcNext($this) {
    $this._nextItem = $this._nextState === -2 ? $this._this$0_2._getInitialValue() : $this._this$0_2._getNextValue(ensureNotNull($this._nextItem));
    $this._nextState = $this._nextItem == null ? 0 : 1;
  }
  function _no_name_provided__4(this$0) {
    this._this$0_2 = this$0;
    this._nextItem = null;
    this._nextState = -2;
  }
  _no_name_provided__4.prototype.next_16 = function () {
    if (this._nextState < 0)
      calcNext(this);
    if (this._nextState === 0)
      throw NoSuchElementException_init_$Create$();
    var tmp = this._nextItem;
    var result = isObject(tmp) ? tmp : THROW_CCE();
    this._nextState = -1;
    return result;
  };
  _no_name_provided__4.prototype.hasNext_14 = function () {
    if (this._nextState < 0)
      calcNext(this);
    return this._nextState === 1;
  };
  _no_name_provided__4.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  function GeneratorSequence(getInitialValue, getNextValue) {
    this._getInitialValue = getInitialValue;
    this._getNextValue = getNextValue;
  }
  GeneratorSequence.prototype.iterator_40 = function () {
    return new _no_name_provided__4(this);
  };
  GeneratorSequence.$metadata$ = {
    simpleName: 'GeneratorSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function emptySequence() {
    return EmptySequence_getInstance();
  }
  function DropTakeSequence() {
  }
  DropTakeSequence.$metadata$ = {
    simpleName: 'DropTakeSequence',
    kind: 'interface',
    interfaces: [Sequence]
  };
  function _no_name_provided__5(this$0) {
    this._this$0_3 = this$0;
    this._left = this._this$0_3._count;
    this._iterator = this._this$0_3._sequence.iterator_40();
  }
  _no_name_provided__5.prototype.next_16 = function () {
    if (this._left === 0)
      throw NoSuchElementException_init_$Create$();
    var tmp0_this = this;
    var tmp1 = tmp0_this._left;
    tmp0_this._left = tmp1 - 1 | 0;
    Unit_getInstance();
    return this._iterator.next_16();
  };
  _no_name_provided__5.prototype.hasNext_14 = function () {
    return this._left > 0 ? this._iterator.hasNext_14() : false;
  };
  _no_name_provided__5.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  function TakeSequence(sequence, count) {
    this._sequence = sequence;
    this._count = count;
    var tmp0_require_0 = this._count >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'count must be non-negative, but was ' + this._count + '.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }}
  TakeSequence.prototype.take_1 = function (n) {
    return n >= this._count ? this : new TakeSequence(this._sequence, n);
  };
  TakeSequence.prototype.iterator_40 = function () {
    return new _no_name_provided__5(this);
  };
  TakeSequence.$metadata$ = {
    simpleName: 'TakeSequence',
    kind: 'class',
    interfaces: [Sequence, DropTakeSequence]
  };
  function _no_name_provided__6(this$0) {
    this._this$0_4 = this$0;
    this._iterator_0 = this._this$0_4._sequence_0.iterator_40();
  }
  _no_name_provided__6.prototype.next_16 = function () {
    return this._this$0_4._transformer(this._iterator_0.next_16());
  };
  _no_name_provided__6.prototype.hasNext_14 = function () {
    return this._iterator_0.hasNext_14();
  };
  _no_name_provided__6.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  function TransformingSequence(sequence, transformer) {
    this._sequence_0 = sequence;
    this._transformer = transformer;
  }
  TransformingSequence.prototype.iterator_40 = function () {
    return new _no_name_provided__6(this);
  };
  TransformingSequence.$metadata$ = {
    simpleName: 'TransformingSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function EmptySequence() {
    EmptySequence_instance = this;
  }
  EmptySequence.prototype.iterator_40 = function () {
    return EmptyIterator_getInstance();
  };
  EmptySequence.prototype.take_1 = function (n) {
    return EmptySequence_getInstance();
  };
  EmptySequence.$metadata$ = {
    simpleName: 'EmptySequence',
    kind: 'object',
    interfaces: [Sequence, DropTakeSequence]
  };
  var EmptySequence_instance;
  function EmptySequence_getInstance() {
    if (EmptySequence_instance == null)
      new EmptySequence();
    return EmptySequence_instance;
  }
  function emptySet() {
    return EmptySet_getInstance();
  }
  function EmptySet() {
    EmptySet_instance = this;
    this._serialVersionUID_1 = new Long(1993859828, 793161749);
  }
  EmptySet.prototype.equals = function (other) {
    var tmp;
    if (!(other == null) ? isInterface(other, Set) : false) {
      tmp = other.isEmpty_28();
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  EmptySet.prototype.hashCode = function () {
    return 0;
  };
  EmptySet.prototype.toString = function () {
    return '[]';
  };
  EmptySet.prototype._get_size__32 = function () {
    return 0;
  };
  EmptySet.prototype.isEmpty_28 = function () {
    return true;
  };
  EmptySet.prototype.containsAll_6 = function (elements) {
    return elements.isEmpty_28();
  };
  EmptySet.prototype.containsAll_19 = function (elements) {
    return this.containsAll_6(elements);
  };
  EmptySet.prototype.iterator_40 = function () {
    return EmptyIterator_getInstance();
  };
  EmptySet.$metadata$ = {
    simpleName: 'EmptySet',
    kind: 'object',
    interfaces: [Set, Serializable]
  };
  var EmptySet_instance;
  function EmptySet_getInstance() {
    if (EmptySet_instance == null)
      new EmptySet();
    return EmptySet_instance;
  }
  function optimizeReadOnlySet(_this_) {
    var tmp0_subject = _this_._get_size__32();
    switch (tmp0_subject) {
      case 0:
        return emptySet();
      case 1:
        return setOf(_this_.iterator_40().next_16());
      default:return _this_;
    }
  }
  function hashSetOf(elements) {
    return toCollection(elements, HashSet_init_$Create$(mapCapacity(elements.length)));
  }
  function KClassifier() {
  }
  KClassifier.$metadata$ = {
    simpleName: 'KClassifier',
    kind: 'interface',
    interfaces: []
  };
  function appendElement(_this_, element, transform) {
    if (!(transform == null)) {
      _this_.append_3(transform(element));
      Unit_getInstance();
    } else {
      if (element == null ? true : isCharSequence(element)) {
        _this_.append_3(element);
        Unit_getInstance();
      } else {
        if (element instanceof Char) {
          _this_.append_2(element);
          Unit_getInstance();
        } else {
          {
            _this_.append_3(toString_0(element));
            Unit_getInstance();
          }
        }
      }
    }
  }
  function equals(_this_, other, ignoreCase) {
    if (_this_.equals(other))
      return true;
    if (!ignoreCase)
      return false;
    var thisUpper = uppercaseChar(_this_);
    var otherUpper = uppercaseChar(other);
    var tmp;
    if (thisUpper.equals(otherUpper)) {
      tmp = true;
    } else {
      var tmp0_asDynamic_0 = thisUpper.toString();
      var tmp1_unsafeCast_0 = tmp0_asDynamic_0.toLowerCase();
      var tmp_0 = charSequenceGet(tmp1_unsafeCast_0, 0);
      var tmp2_asDynamic_0 = otherUpper.toString();
      var tmp3_unsafeCast_0 = tmp2_asDynamic_0.toLowerCase();
      tmp = tmp_0.equals(charSequenceGet(tmp3_unsafeCast_0, 0));
    }
    return tmp;
  }
  function trimIndent(_this_) {
    return replaceIndent(_this_, '');
  }
  function replaceIndent(_this_, newIndent) {
    var lines_0 = lines(_this_);
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = lines_0.iterator_40();
    while (tmp0_iterator_1_2.hasNext_14()) {
      var element_2_3 = tmp0_iterator_1_2.next_16();
      if (isNotBlank(element_2_3)) {
        tmp0_filterTo_0_1.add_16(element_2_3);
        Unit_getInstance();
      }}
    var tmp0_map_0 = tmp0_filterTo_0_1;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp0_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp0_map_0.iterator_40();
    while (tmp0_iterator_1_2_0.hasNext_14()) {
      var item_2_3 = tmp0_iterator_1_2_0.next_16();
      tmp0_mapTo_0_1.add_16(indentWidth(item_2_3));
      Unit_getInstance();
    }
    var tmp0_elvis_lhs = minOrNull(tmp0_mapTo_0_1);
    var minCommonIndent = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
    var tmp1_reindent_0 = _this_.length + imul(newIndent.length, lines_0._get_size__32()) | 0;
    var tmp2_reindent_0 = getIndentFunction(newIndent);
    var lastIndex_1 = _get_lastIndex_(lines_0);
    var tmp1_mapIndexedNotNullTo_0_1 = ArrayList_init_$Create$();
    var index_1_2 = 0;
    var tmp0_iterator_2_3 = lines_0.iterator_40();
    while (tmp0_iterator_2_3.hasNext_14()) {
      var item_3_4 = tmp0_iterator_2_3.next_16();
      var tmp1_4_6 = index_1_2;
      index_1_2 = tmp1_4_6 + 1 | 0;
      var tmp0__anonymous__1_5_5 = checkIndexOverflow(tmp1_4_6);
      var tmp;
      if ((tmp0__anonymous__1_5_5 === 0 ? true : tmp0__anonymous__1_5_5 === lastIndex_1) ? isBlank(item_3_4) : false) {
        tmp = null;
      } else {
        var tmp0_safe_receiver_4_10 = drop_0(item_3_4, minCommonIndent);
        var tmp_0;
        if (tmp0_safe_receiver_4_10 == null) {
          tmp_0 = null;
        } else {
          tmp_0 = tmp2_reindent_0(tmp0_safe_receiver_4_10);
        }
        var tmp1_elvis_lhs_3_9 = tmp_0;
        tmp = tmp1_elvis_lhs_3_9 == null ? item_3_4 : tmp1_elvis_lhs_3_9;
      }
      var tmp0_safe_receiver_2_6_7 = tmp;
      if (tmp0_safe_receiver_2_6_7 == null)
        null;
      else {
        tmp1_mapIndexedNotNullTo_0_1.add_16(tmp0_safe_receiver_2_6_7);
        Unit_getInstance();
      }
      Unit_getInstance();
    }
    var tmp_1 = StringBuilder_init_$Create$(tmp1_reindent_0);
    return joinTo$default(tmp1_mapIndexedNotNullTo_0_1, tmp_1, '\n', null, null, 0, null, null, 124, null).toString();
  }
  function indentWidth(_this_) {
    var tmp$ret$0;
    l$ret$1: do {
      var inductionVariable = 0;
      var last_1 = charSequenceLength(_this_) - 1 | 0;
      if (inductionVariable <= last_1)
        do {
          var index_2 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp0__anonymous__3 = charSequenceGet(_this_, index_2);
          if (!isWhitespace(tmp0__anonymous__3)) {
            tmp$ret$0 = index_2;
            break l$ret$1;
          } else {
          }
        }
         while (inductionVariable <= last_1);
      tmp$ret$0 = -1;
    }
     while (false);
    var tmp1_let_0 = tmp$ret$0;
    return tmp1_let_0 === -1 ? _this_.length : tmp1_let_0;
  }
  function getIndentFunction(indent) {
    var tmp;
    if (charSequenceLength(indent) === 0) {
      tmp = _no_name_provided_$factory_1();
    } else {
      {
        tmp = _no_name_provided_$factory_2(indent);
      }
    }
    return tmp;
  }
  function _no_name_provided__7() {
  }
  _no_name_provided__7.prototype.invoke_5 = function (line) {
    return line;
  };
  _no_name_provided__7.prototype.invoke_84 = function (p1) {
    return this.invoke_5((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__7.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__8($indent) {
    this._$indent = $indent;
  }
  _no_name_provided__8.prototype.invoke_5 = function (line) {
    return this._$indent + line;
  };
  _no_name_provided__8.prototype.invoke_84 = function (p1) {
    return this.invoke_5((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__8.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_1() {
    var i = new _no_name_provided__7();
    return function (p1) {
      return i.invoke_5(p1);
    };
  }
  function _no_name_provided_$factory_2($indent) {
    var i = new _no_name_provided__8($indent);
    return function (p1) {
      return i.invoke_5(p1);
    };
  }
  function indexOf_0(_this_, string, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      var tmp_1 = charSequenceLength(_this_);
      tmp = indexOf$default_0(_this_, string, startIndex, tmp_1, ignoreCase, false, 16, null);
    } else {
      {
        var tmp0_nativeIndexOf_0 = _this_;
        tmp = tmp0_nativeIndexOf_0.indexOf(string, startIndex);
      }
    }
    return tmp;
  }
  function indexOf$default(_this_, string, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return indexOf_0(_this_, string, startIndex, ignoreCase);
  }
  function indexOf_1(_this_, other, startIndex, endIndex, ignoreCase, last_1) {
    var indices = !last_1 ? numberRangeToNumber(coerceAtLeast(startIndex, 0), coerceAtMost(endIndex, charSequenceLength(_this_))) : downTo(coerceAtMost(startIndex, _get_lastIndex__0(_this_)), coerceAtLeast(endIndex, 0));
    var tmp;
    if (typeof _this_ === 'string') {
      tmp = typeof other === 'string';
    } else {
      {
        tmp = false;
      }
    }
    if (tmp) {
      var inductionVariable = indices._first_0;
      var last_2 = indices._last;
      var step = indices._step_0;
      if ((step > 0 ? inductionVariable <= last_2 : false) ? true : step < 0 ? last_2 <= inductionVariable : false)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + step | 0;
          if (regionMatches(other, 0, _this_, index, charSequenceLength(other), ignoreCase))
            return index;
        }
         while (!(index === last_2));
    } else {
      {
        var inductionVariable_0 = indices._first_0;
        var last_3 = indices._last;
        var step_0 = indices._step_0;
        if ((step_0 > 0 ? inductionVariable_0 <= last_3 : false) ? true : step_0 < 0 ? last_3 <= inductionVariable_0 : false)
          do {
            var index_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + step_0 | 0;
            if (regionMatchesImpl(other, 0, _this_, index_0, charSequenceLength(other), ignoreCase))
              return index_0;
          }
           while (!(index_0 === last_3));
      }
    }
    return -1;
  }
  function indexOf$default_0(_this_, other, startIndex, endIndex, ignoreCase, last_1, $mask0, $handler) {
    if (!(($mask0 & 16) === 0))
      last_1 = false;
    return indexOf_1(_this_, other, startIndex, endIndex, ignoreCase, last_1);
  }
  function trim(_this_) {
    var startIndex_1 = 0;
    var endIndex_2 = charSequenceLength(_this_) - 1 | 0;
    var startFound_3 = false;
    $l$break: while (startIndex_1 <= endIndex_2) {
      var index_4 = !startFound_3 ? startIndex_1 : endIndex_2;
      var match_5 = isWhitespace(charSequenceGet(_this_, index_4));
      if (!startFound_3) {
        if (!match_5)
          startFound_3 = true;
        else
          startIndex_1 = startIndex_1 + 1 | 0;
      } else {
        if (!match_5)
          break $l$break;
        else
          endIndex_2 = endIndex_2 - 1 | 0;
      }
    }
    return charSequenceSubSequence(_this_, startIndex_1, endIndex_2 + 1 | 0);
  }
  function _get_lastIndex__0(_this_) {
    return charSequenceLength(_this_) - 1 | 0;
  }
  function regionMatchesImpl(_this_, thisOffset, other, otherOffset, length, ignoreCase) {
    if (((otherOffset < 0 ? true : thisOffset < 0) ? true : thisOffset > (charSequenceLength(_this_) - length | 0)) ? true : otherOffset > (charSequenceLength(other) - length | 0)) {
      return false;
    }var inductionVariable = 0;
    if (inductionVariable < length)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(charSequenceGet(_this_, thisOffset + index | 0), charSequenceGet(other, otherOffset + index | 0), ignoreCase))
          return false;
      }
       while (inductionVariable < length);
    return true;
  }
  function lines(_this_) {
    return toList_0(lineSequence(_this_));
  }
  function isNotBlank(_this_) {
    return !isBlank(_this_);
  }
  function lineSequence(_this_) {
    var tmp = ['\r\n', '\n', '\r'];
    return splitToSequence$default(_this_, tmp, false, 0, 6, null);
  }
  function splitToSequence(_this_, delimiters, ignoreCase, limit) {
    var tmp = rangesDelimitedBy$default(_this_, delimiters, 0, ignoreCase, limit, 2, null);
    return map(tmp, _no_name_provided_$factory_3(_this_));
  }
  function splitToSequence$default(_this_, delimiters, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      ignoreCase = false;
    if (!(($mask0 & 4) === 0))
      limit = 0;
    return splitToSequence(_this_, delimiters, ignoreCase, limit);
  }
  function substring(_this_, range) {
    return toString_1(charSequenceSubSequence(_this_, range._get_start_(), range._get_endInclusive_() + 1 | 0));
  }
  function rangesDelimitedBy(_this_, delimiters, startIndex, ignoreCase, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_2 = '' + 'Limit must be non-negative, but was ' + limit + '.';
      throw IllegalArgumentException_init_$Create$(toString_1(message_2));
    }var delimitersList = asList(delimiters);
    return new DelimitedRangesSequence(_this_, startIndex, limit, _no_name_provided_$factory_4(delimitersList, ignoreCase));
  }
  function rangesDelimitedBy$default(_this_, delimiters, startIndex, ignoreCase, limit, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    if (!(($mask0 & 8) === 0))
      limit = 0;
    return rangesDelimitedBy(_this_, delimiters, startIndex, ignoreCase, limit);
  }
  function calcNext_0($this) {
    if ($this._nextSearchIndex < 0) {
      $this._nextState_0 = 0;
      $this._nextItem_0 = null;
    } else {
      var tmp;
      var tmp_0;
      if ($this._this$0_5._limit > 0) {
        var tmp0_this = $this;
        tmp0_this._counter = tmp0_this._counter + 1 | 0;
        tmp_0 = tmp0_this._counter >= $this._this$0_5._limit;
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = true;
      } else {
        {
          tmp = $this._nextSearchIndex > charSequenceLength($this._this$0_5._input);
        }
      }
      if (tmp) {
        $this._nextItem_0 = numberRangeToNumber($this._currentStartIndex, _get_lastIndex__0($this._this$0_5._input));
        $this._nextSearchIndex = -1;
      } else {
        {
          var match = $this._this$0_5._getNextMatch($this._this$0_5._input, $this._nextSearchIndex);
          if (match == null) {
            $this._nextItem_0 = numberRangeToNumber($this._currentStartIndex, _get_lastIndex__0($this._this$0_5._input));
            $this._nextSearchIndex = -1;
          } else {
            var tmp1_container = match;
            var index = tmp1_container.component1();
            var length = tmp1_container.component2();
            $this._nextItem_0 = until($this._currentStartIndex, index);
            $this._currentStartIndex = index + length | 0;
            $this._nextSearchIndex = $this._currentStartIndex + (length === 0 ? 1 : 0) | 0;
          }
        }
      }
      $this._nextState_0 = 1;
    }
  }
  function _no_name_provided__9(this$0) {
    this._this$0_5 = this$0;
    this._nextState_0 = -1;
    this._currentStartIndex = coerceIn(this._this$0_5._startIndex, 0, charSequenceLength(this._this$0_5._input));
    this._nextSearchIndex = this._currentStartIndex;
    this._nextItem_0 = null;
    this._counter = 0;
  }
  _no_name_provided__9.prototype.next_16 = function () {
    if (this._nextState_0 === -1)
      calcNext_0(this);
    if (this._nextState_0 === 0)
      throw NoSuchElementException_init_$Create$();
    var tmp = this._nextItem_0;
    var result = tmp instanceof IntRange ? tmp : THROW_CCE();
    this._nextItem_0 = null;
    this._nextState_0 = -1;
    return result;
  };
  _no_name_provided__9.prototype.hasNext_14 = function () {
    if (this._nextState_0 === -1)
      calcNext_0(this);
    return this._nextState_0 === 1;
  };
  _no_name_provided__9.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  function DelimitedRangesSequence(input, startIndex, limit, getNextMatch) {
    this._input = input;
    this._startIndex = startIndex;
    this._limit = limit;
    this._getNextMatch = getNextMatch;
  }
  DelimitedRangesSequence.prototype.iterator_40 = function () {
    return new _no_name_provided__9(this);
  };
  DelimitedRangesSequence.$metadata$ = {
    simpleName: 'DelimitedRangesSequence',
    kind: 'class',
    interfaces: [Sequence]
  };
  function findAnyOf(_this_, strings, startIndex, ignoreCase, last_1) {
    if (!ignoreCase ? strings._get_size__32() === 1 : false) {
      var string = single(strings);
      var tmp;
      if (!last_1) {
        tmp = indexOf$default(_this_, string, startIndex, false, 4, null);
      } else {
        tmp = lastIndexOf$default(_this_, string, startIndex, false, 4, null);
      }
      var index = tmp;
      return index < 0 ? null : to(index, string);
    }var indices = !last_1 ? numberRangeToNumber(coerceAtLeast(startIndex, 0), charSequenceLength(_this_)) : downTo(coerceAtMost(startIndex, _get_lastIndex__0(_this_)), 0);
    if (typeof _this_ === 'string') {
      var inductionVariable = indices._first_0;
      var last_2 = indices._last;
      var step = indices._step_0;
      if ((step > 0 ? inductionVariable <= last_2 : false) ? true : step < 0 ? last_2 <= inductionVariable : false)
        do {
          var index_0 = inductionVariable;
          inductionVariable = inductionVariable + step | 0;
          var tmp$ret$0;
          l$ret$1: do {
            var tmp0_iterator_1 = strings.iterator_40();
            while (tmp0_iterator_1.hasNext_14()) {
              var element_2 = tmp0_iterator_1.next_16();
              if (regionMatches(element_2, 0, _this_, index_0, element_2.length, ignoreCase)) {
                tmp$ret$0 = element_2;
                break l$ret$1;
              } else {
              }
            }
            tmp$ret$0 = null;
          }
           while (false);
          var matchingString = tmp$ret$0;
          if (!(matchingString == null))
            return to(index_0, matchingString);
        }
         while (!(index_0 === last_2));
    } else {
      {
        var inductionVariable_0 = indices._first_0;
        var last_3 = indices._last;
        var step_0 = indices._step_0;
        if ((step_0 > 0 ? inductionVariable_0 <= last_3 : false) ? true : step_0 < 0 ? last_3 <= inductionVariable_0 : false)
          do {
            var index_1 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + step_0 | 0;
            var tmp$ret$2;
            l$ret$3: do {
              var tmp0_iterator_1_0 = strings.iterator_40();
              while (tmp0_iterator_1_0.hasNext_14()) {
                var element_2_0 = tmp0_iterator_1_0.next_16();
                if (regionMatchesImpl(element_2_0, 0, _this_, index_1, element_2_0.length, ignoreCase)) {
                  tmp$ret$2 = element_2_0;
                  break l$ret$3;
                } else {
                }
              }
              tmp$ret$2 = null;
            }
             while (false);
            var matchingString_0 = tmp$ret$2;
            if (!(matchingString_0 == null))
              return to(index_1, matchingString_0);
          }
           while (!(index_1 === last_3));
      }
    }
    return null;
  }
  function lastIndexOf(_this_, string, startIndex, ignoreCase) {
    var tmp;
    var tmp_0;
    if (ignoreCase) {
      tmp_0 = true;
    } else {
      tmp_0 = !(typeof _this_ === 'string');
    }
    if (tmp_0) {
      tmp = indexOf_1(_this_, string, startIndex, 0, ignoreCase, true);
    } else {
      {
        var tmp0_nativeLastIndexOf_0 = _this_;
        tmp = tmp0_nativeLastIndexOf_0.lastIndexOf(string, startIndex);
      }
    }
    return tmp;
  }
  function lastIndexOf$default(_this_, string, startIndex, ignoreCase, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = _get_lastIndex__0(_this_);
    if (!(($mask0 & 4) === 0))
      ignoreCase = false;
    return lastIndexOf(_this_, string, startIndex, ignoreCase);
  }
  function _no_name_provided__10($this_splitToSequence) {
    this._$this_splitToSequence = $this_splitToSequence;
  }
  _no_name_provided__10.prototype.invoke_7 = function (it) {
    return substring(this._$this_splitToSequence, it);
  };
  _no_name_provided__10.prototype.invoke_84 = function (p1) {
    return this.invoke_7(p1 instanceof IntRange ? p1 : THROW_CCE());
  };
  _no_name_provided__10.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__11($delimitersList, $ignoreCase) {
    this._$delimitersList = $delimitersList;
    this._$ignoreCase = $ignoreCase;
  }
  _no_name_provided__11.prototype.invoke_9 = function ($this$$receiver, currentIndex) {
    var tmp0_safe_receiver = findAnyOf($this$$receiver, this._$delimitersList, currentIndex, this._$ignoreCase, false);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = to(tmp0_safe_receiver._first, tmp0_safe_receiver._second.length);
    }
    return tmp;
  };
  _no_name_provided__11.prototype.invoke_64 = function (p1, p2) {
    var tmp = (!(p1 == null) ? isCharSequence(p1) : false) ? p1 : THROW_CCE();
    return this.invoke_9(tmp, (!(p2 == null) ? typeof p2 === 'number' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__11.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_3($this_splitToSequence) {
    var i = new _no_name_provided__10($this_splitToSequence);
    return function (p1) {
      return i.invoke_7(p1);
    };
  }
  function _no_name_provided_$factory_4($delimitersList, $ignoreCase) {
    var i = new _no_name_provided__11($delimitersList, $ignoreCase);
    return function (p1, p2) {
      return i.invoke_9(p1, p2);
    };
  }
  function MatchResult() {
  }
  MatchResult.$metadata$ = {
    simpleName: 'MatchResult',
    kind: 'interface',
    interfaces: []
  };
  function MatchGroupCollection() {
  }
  MatchGroupCollection.$metadata$ = {
    simpleName: 'MatchGroupCollection',
    kind: 'interface',
    interfaces: [Collection]
  };
  function Pair(first_0, second) {
    this._first = first_0;
    this._second = second;
  }
  Pair.prototype.toString = function () {
    return '' + '(' + this._first + ', ' + this._second + ')';
  };
  Pair.prototype.component1 = function () {
    return this._first;
  };
  Pair.prototype.component2 = function () {
    return this._second;
  };
  Pair.prototype.hashCode = function () {
    var result = this._first == null ? 0 : hashCode(this._first);
    result = imul(result, 31) + (this._second == null ? 0 : hashCode(this._second)) | 0;
    return result;
  };
  Pair.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Pair))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof Pair ? other : THROW_CCE();
    if (!equals_0(this._first, tmp0_other_with_cast._first))
      return false;
    if (!equals_0(this._second, tmp0_other_with_cast._second))
      return false;
    return true;
  };
  Pair.$metadata$ = {
    simpleName: 'Pair',
    kind: 'class',
    interfaces: [Serializable]
  };
  function to(_this_, that) {
    return new Pair(_this_, that);
  }
  function _UShort___init__impl_(data) {
    return data;
  }
  function _UShort___get_data__impl_(this_0) {
    return this_0;
  }
  function CharSequence() {
  }
  CharSequence.$metadata$ = {
    simpleName: 'CharSequence',
    kind: 'interface',
    interfaces: []
  };
  function Comparable() {
  }
  Comparable.$metadata$ = {
    simpleName: 'Comparable',
    kind: 'interface',
    interfaces: []
  };
  function Iterator() {
  }
  Iterator.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'interface',
    interfaces: []
  };
  function ListIterator() {
  }
  ListIterator.$metadata$ = {
    simpleName: 'ListIterator',
    kind: 'interface',
    interfaces: [Iterator]
  };
  function MutableListIterator() {
  }
  MutableListIterator.$metadata$ = {
    simpleName: 'MutableListIterator',
    kind: 'interface',
    interfaces: [ListIterator, MutableIterator]
  };
  function MutableIterator() {
  }
  MutableIterator.$metadata$ = {
    simpleName: 'MutableIterator',
    kind: 'interface',
    interfaces: [Iterator]
  };
  function Number_0() {
  }
  Number_0.$metadata$ = {
    simpleName: 'Number',
    kind: 'class',
    interfaces: []
  };
  function IntIterator() {
  }
  IntIterator.prototype.next_16 = function () {
    return this.nextInt_0();
  };
  IntIterator.$metadata$ = {
    simpleName: 'IntIterator',
    kind: 'class',
    interfaces: [Iterator]
  };
  function IntProgressionIterator(first_0, last_1, step) {
    IntIterator.call(this);
    this._step = step;
    this._finalElement = last_1;
    this._hasNext = this._step > 0 ? first_0 <= last_1 : first_0 >= last_1;
    this._next = this._hasNext ? first_0 : this._finalElement;
  }
  IntProgressionIterator.prototype.hasNext_14 = function () {
    return this._hasNext;
  };
  IntProgressionIterator.prototype.nextInt_0 = function () {
    var value = this._next;
    if (value === this._finalElement) {
      if (!this._hasNext)
        throw NoSuchElementException_init_$Create$();
      this._hasNext = false;
    } else {
      var tmp0_this = this;
      tmp0_this._next = tmp0_this._next + this._step | 0;
    }
    return value;
  };
  IntProgressionIterator.$metadata$ = {
    simpleName: 'IntProgressionIterator',
    kind: 'class',
    interfaces: []
  };
  function Companion_3() {
    Companion_instance_2 = this;
  }
  Companion_3.prototype.fromClosedRange = function (rangeStart, rangeEnd, step) {
    return new IntProgression(rangeStart, rangeEnd, step);
  };
  Companion_3.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_3();
    return Companion_instance_2;
  }
  function IntProgression(start, endInclusive, step) {
    Companion_getInstance_2();
    if (step === 0)
      throw IllegalArgumentException_init_$Create$('Step must be non-zero.');
    if (step === IntCompanionObject_getInstance()._MIN_VALUE)
      throw IllegalArgumentException_init_$Create$('Step must be greater than Int.MIN_VALUE to avoid overflow on negation.');
    this._first_0 = start;
    this._last = getProgressionLastElement(start, endInclusive, step);
    this._step_0 = step;
  }
  IntProgression.prototype._get_first__0 = function () {
    return this._first_0;
  };
  IntProgression.prototype._get_last__0 = function () {
    return this._last;
  };
  IntProgression.prototype.iterator_40 = function () {
    return new IntProgressionIterator(this._first_0, this._last, this._step_0);
  };
  IntProgression.prototype.isEmpty_28 = function () {
    return this._step_0 > 0 ? this._first_0 > this._last : this._first_0 < this._last;
  };
  IntProgression.prototype.equals = function (other) {
    var tmp;
    if (other instanceof IntProgression) {
      tmp = (this.isEmpty_28() ? other.isEmpty_28() : false) ? true : (this._first_0 === other._first_0 ? this._last === other._last : false) ? this._step_0 === other._step_0 : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  IntProgression.prototype.hashCode = function () {
    return this.isEmpty_28() ? -1 : imul(31, imul(31, this._first_0) + this._last | 0) + this._step_0 | 0;
  };
  IntProgression.prototype.toString = function () {
    return this._step_0 > 0 ? '' + this._first_0 + '..' + this._last + ' step ' + this._step_0 : '' + this._first_0 + ' downTo ' + this._last + ' step ' + (-this._step_0 | 0);
  };
  IntProgression.$metadata$ = {
    simpleName: 'IntProgression',
    kind: 'class',
    interfaces: [Iterable]
  };
  function ClosedRange() {
  }
  ClosedRange.$metadata$ = {
    simpleName: 'ClosedRange',
    kind: 'interface',
    interfaces: []
  };
  function Companion_4() {
    Companion_instance_3 = this;
    this._EMPTY = new IntRange(1, 0);
  }
  Companion_4.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_4();
    return Companion_instance_3;
  }
  function IntRange(start, endInclusive) {
    Companion_getInstance_3();
    IntProgression.call(this, start, endInclusive, 1);
  }
  IntRange.prototype._get_start_ = function () {
    return this._get_first__0();
  };
  IntRange.prototype._get_endInclusive_ = function () {
    return this._get_last__0();
  };
  IntRange.prototype.isEmpty_28 = function () {
    return this._get_first__0() > this._get_last__0();
  };
  IntRange.prototype.equals = function (other) {
    var tmp;
    if (other instanceof IntRange) {
      tmp = (this.isEmpty_28() ? other.isEmpty_28() : false) ? true : this._get_first__0() === other._get_first__0() ? this._get_last__0() === other._get_last__0() : false;
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  IntRange.prototype.hashCode = function () {
    return this.isEmpty_28() ? -1 : imul(31, this._get_first__0()) + this._get_last__0() | 0;
  };
  IntRange.prototype.toString = function () {
    return '' + this._get_first__0() + '..' + this._get_last__0();
  };
  IntRange.$metadata$ = {
    simpleName: 'IntRange',
    kind: 'class',
    interfaces: [ClosedRange]
  };
  function Unit() {
    Unit_instance = this;
  }
  Unit.prototype.toString = function () {
    return 'kotlin.Unit';
  };
  Unit.$metadata$ = {
    simpleName: 'Unit',
    kind: 'object',
    interfaces: []
  };
  var Unit_instance;
  function Unit_getInstance() {
    if (Unit_instance == null)
      new Unit();
    return Unit_instance;
  }
  function getProgressionLastElement(start, end, step) {
    var tmp;
    if (step > 0) {
      tmp = start >= end ? end : end - differenceModulo(end, start, step) | 0;
    } else if (step < 0) {
      tmp = start <= end ? end : end + differenceModulo(start, end, -step | 0) | 0;
    } else {
      throw IllegalArgumentException_init_$Create$('Step is zero.');
    }
    return tmp;
  }
  function differenceModulo(a, b, c) {
    return mod(mod(a, c) - mod(b, c) | 0, c);
  }
  function mod(a, b) {
    var mod_0 = a % b;
    return mod_0 >= 0 ? mod_0 : mod_0 + b | 0;
  }
  function IntCompanionObject_0() {
    IntCompanionObject_instance = this;
    this._MIN_VALUE = -2147483648;
    this._MAX_VALUE = 2147483647;
    this._SIZE_BYTES = 4;
    this._SIZE_BITS = 32;
  }
  IntCompanionObject_0.prototype._get_MIN_VALUE_ = function () {
    return this._MIN_VALUE;
  };
  IntCompanionObject_0.prototype._get_MAX_VALUE_ = function () {
    return this._MAX_VALUE;
  };
  IntCompanionObject_0.prototype._get_SIZE_BYTES_ = function () {
    return this._SIZE_BYTES;
  };
  IntCompanionObject_0.prototype._get_SIZE_BITS_ = function () {
    return this._SIZE_BITS;
  };
  IntCompanionObject_0.$metadata$ = {
    simpleName: 'IntCompanionObject',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(IntCompanionObject_0.prototype, 'MIN_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MIN_VALUE_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'MAX_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MAX_VALUE_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BYTES', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BYTES_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BITS', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BITS_
  });
  var IntCompanionObject_instance;
  function IntCompanionObject_getInstance() {
    if (IntCompanionObject_instance == null)
      new IntCompanionObject_0();
    return IntCompanionObject_instance;
  }
  function listOf_0(element) {
    return arrayListOf([element]);
  }
  function mapCapacity(expectedSize) {
    return expectedSize;
  }
  function setOf(element) {
    return hashSetOf([element]);
  }
  function checkIndexOverflow(index) {
    if (index < 0) {
      throwIndexOverflow();
    }return index;
  }
  function copyToArrayImpl_0(collection) {
    var array = [];
    var iterator = collection.iterator_40();
    while (iterator.hasNext_14()) {
      array.push(iterator.next_16());
    }
    return array;
  }
  function copyToArray_0(collection) {
    var tmp;
    if (collection.toArray !== undefined) {
      var tmp0_unsafeCast_0 = collection.toArray();
      tmp = tmp0_unsafeCast_0;
    } else {
      {
        var tmp1_unsafeCast_0 = copyToArrayImpl_0(collection);
        tmp = tmp1_unsafeCast_0;
      }
    }
    return tmp;
  }
  function AbstractMutableCollection() {
    AbstractCollection.call(this);
  }
  AbstractMutableCollection.prototype.toJSON = function () {
    return this.toArray();
  };
  AbstractMutableCollection.prototype.checkIsMutable_4 = function () {
  };
  AbstractMutableCollection.$metadata$ = {
    simpleName: 'AbstractMutableCollection',
    kind: 'class',
    interfaces: [MutableCollection]
  };
  function IteratorImpl($outer) {
    this._$this = $outer;
    this._index = 0;
    this._last_0 = -1;
  }
  IteratorImpl.prototype._set_index__0 = function (_set___) {
    this._index = _set___;
  };
  IteratorImpl.prototype.hasNext_14 = function () {
    return this._index < this._$this._get_size__32();
  };
  IteratorImpl.prototype.next_16 = function () {
    if (!this.hasNext_14())
      throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp0_this = this;
    var tmp1 = tmp0_this._index;
    tmp0_this._index = tmp1 + 1 | 0;
    tmp._last_0 = tmp1;
    return this._$this.get_30(this._last_0);
  };
  IteratorImpl.$metadata$ = {
    simpleName: 'IteratorImpl',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ListIteratorImpl($outer, index) {
    this._$this_0 = $outer;
    IteratorImpl.call(this, $outer);
    Companion_getInstance().checkPositionIndex(index, this._$this_0._get_size__32());
    this._set_index__0(index);
  }
  ListIteratorImpl.$metadata$ = {
    simpleName: 'ListIteratorImpl',
    kind: 'class',
    interfaces: [MutableListIterator]
  };
  function AbstractMutableList() {
    AbstractMutableCollection.call(this);
    this._modCount = 0;
  }
  AbstractMutableList.prototype._set_modCount__0 = function (_set___) {
    this._modCount = _set___;
  };
  AbstractMutableList.prototype._get_modCount__0 = function () {
    return this._modCount;
  };
  AbstractMutableList.prototype.add_16 = function (element) {
    this.checkIsMutable_4();
    this.add_8(this._get_size__32(), element);
    return true;
  };
  AbstractMutableList.prototype.iterator_40 = function () {
    return new IteratorImpl(this);
  };
  AbstractMutableList.prototype.contains_19 = function (element) {
    return this.indexOf_0(element) >= 0;
  };
  AbstractMutableList.prototype.indexOf_0 = function (element) {
    var inductionVariable = 0;
    var last_1 = _get_lastIndex_(this);
    if (inductionVariable <= last_1)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals_0(this.get_30(index), element)) {
          return index;
        }}
       while (!(index === last_1));
    return -1;
  };
  AbstractMutableList.prototype.listIterator_2 = function (index) {
    return new ListIteratorImpl(this, index);
  };
  AbstractMutableList.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, List) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  AbstractMutableList.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  AbstractMutableList.$metadata$ = {
    simpleName: 'AbstractMutableList',
    kind: 'class',
    interfaces: [MutableList]
  };
  function _no_name_provided__12($entryIterator) {
    this._$entryIterator_0 = $entryIterator;
  }
  _no_name_provided__12.prototype.hasNext_14 = function () {
    return this._$entryIterator_0.hasNext_14();
  };
  _no_name_provided__12.prototype.next_16 = function () {
    return this._$entryIterator_0.next_16()._get_key__7();
  };
  _no_name_provided__12.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function SimpleEntry(key, value) {
    this._key = key;
    this.__value = value;
  }
  SimpleEntry.prototype._get_key__7 = function () {
    return this._key;
  };
  SimpleEntry.prototype._get_value__8 = function () {
    return this.__value;
  };
  SimpleEntry.prototype.setValue_1 = function (newValue) {
    var oldValue = this.__value;
    this.__value = newValue;
    return oldValue;
  };
  SimpleEntry.prototype.hashCode = function () {
    return Companion_getInstance_0().entryHashCode(this);
  };
  SimpleEntry.prototype.toString = function () {
    return Companion_getInstance_0().entryToString(this);
  };
  SimpleEntry.prototype.equals = function (other) {
    return Companion_getInstance_0().entryEquals(this, other);
  };
  SimpleEntry.$metadata$ = {
    simpleName: 'SimpleEntry',
    kind: 'class',
    interfaces: [MutableEntry]
  };
  function AbstractEntrySet() {
    AbstractMutableSet.call(this);
  }
  AbstractEntrySet.prototype.contains_19 = function (element) {
    return this.containsEntry_4(element);
  };
  AbstractEntrySet.$metadata$ = {
    simpleName: 'AbstractEntrySet',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__13(this$0) {
    this._this$0_6 = this$0;
    AbstractMutableSet.call(this);
  }
  _no_name_provided__13.prototype.add_4 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on keys');
  };
  _no_name_provided__13.prototype.add_16 = function (element) {
    return this.add_4((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__13.prototype.contains_15 = function (element) {
    return this._this$0_6.containsKey_9(element);
  };
  _no_name_provided__13.prototype.contains_19 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    else {
    }
    return this.contains_15((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  _no_name_provided__13.prototype.iterator_40 = function () {
    var entryIterator = this._this$0_6._get_entries__7().iterator_40();
    return new _no_name_provided__12(entryIterator);
  };
  _no_name_provided__13.prototype._get_size__32 = function () {
    return this._this$0_6._get_size__32();
  };
  _no_name_provided__13.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableMap() {
    AbstractMap.call(this);
    this.__keys_0 = null;
    this.__values_0 = null;
  }
  AbstractMutableMap.prototype._get_keys__7 = function () {
    if (this.__keys_0 == null) {
      var tmp = this;
      tmp.__keys_0 = new _no_name_provided__13(this);
    }return ensureNotNull(this.__keys_0);
  };
  AbstractMutableMap.prototype.putAll_0 = function (from) {
    this.checkIsMutable_4();
    var tmp0_iterator = from._get_entries__7().iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var tmp1_loop_parameter = tmp0_iterator.next_16();
      var key = tmp1_loop_parameter._get_key__7();
      var value = tmp1_loop_parameter._get_value__8();
      this.put_7(key, value);
      Unit_getInstance();
    }
  };
  AbstractMutableMap.prototype.checkIsMutable_4 = function () {
  };
  AbstractMutableMap.$metadata$ = {
    simpleName: 'AbstractMutableMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function AbstractMutableSet() {
    AbstractMutableCollection.call(this);
  }
  AbstractMutableSet.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Set) : false))
      return false;
    else {
    }
    return Companion_getInstance_1().setEquals(this, other);
  };
  AbstractMutableSet.prototype.hashCode = function () {
    return Companion_getInstance_1().unorderedHashCode(this);
  };
  AbstractMutableSet.$metadata$ = {
    simpleName: 'AbstractMutableSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function ArrayList_init_$Init$($this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$() {
    return ArrayList_init_$Init$(Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_0(initialCapacity, $this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$_0(initialCapacity) {
    return ArrayList_init_$Init$_0(initialCapacity, Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_1(elements, $this) {
    ArrayList.call($this, copyToArray_0(elements));
    return $this;
  }
  function ArrayList_init_$Create$_1(elements) {
    return ArrayList_init_$Init$_1(elements, Object.create(ArrayList.prototype));
  }
  function rangeCheck($this, index) {
    Companion_getInstance().checkElementIndex(index, $this._get_size__32());
    return index;
  }
  function insertionRangeCheck($this, index) {
    Companion_getInstance().checkPositionIndex(index, $this._get_size__32());
    return index;
  }
  function ArrayList(array) {
    AbstractMutableList.call(this);
    this._array = array;
    this._isReadOnly = false;
  }
  ArrayList.prototype._get_size__32 = function () {
    return this._array.length;
  };
  ArrayList.prototype.get_30 = function (index) {
    var tmp = this._array[rangeCheck(this, index)];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayList.prototype.add_16 = function (element) {
    this.checkIsMutable_4();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.push(element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.add_8 = function (index, element) {
    this.checkIsMutable_4();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.splice(insertionRangeCheck(this, index), 0, element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
  };
  ArrayList.prototype.removeAt = function (index) {
    this.checkIsMutable_4();
    rangeCheck(this, index);
    Unit_getInstance();
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    var tmp;
    if (index === _get_lastIndex_(this)) {
      var tmp0_asDynamic_0 = this._array;
      tmp = tmp0_asDynamic_0.pop();
    } else {
      var tmp1_asDynamic_0 = this._array;
      tmp = tmp1_asDynamic_0.splice(index, 1)[0];
    }
    return tmp;
  };
  ArrayList.prototype.indexOf_0 = function (element) {
    return indexOf(this._array, element);
  };
  ArrayList.prototype.toString = function () {
    return arrayToString(this._array);
  };
  ArrayList.prototype.toArray_0 = function () {
    return [].slice.call(this._array);
  };
  ArrayList.prototype.toArray = function () {
    return this.toArray_0();
  };
  ArrayList.prototype.checkIsMutable_4 = function () {
    if (this._isReadOnly)
      throw UnsupportedOperationException_init_$Create$();
  };
  ArrayList.$metadata$ = {
    simpleName: 'ArrayList',
    kind: 'class',
    interfaces: [MutableList, RandomAccess]
  };
  function HashCode() {
    HashCode_instance = this;
  }
  HashCode.prototype.equals_1 = function (value1, value2) {
    return equals_0(value1, value2);
  };
  HashCode.prototype.getHashCode_0 = function (value) {
    var tmp0_safe_receiver = value;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  HashCode.$metadata$ = {
    simpleName: 'HashCode',
    kind: 'object',
    interfaces: [EqualityComparator]
  };
  var HashCode_instance;
  function HashCode_getInstance() {
    if (HashCode_instance == null)
      new HashCode();
    return HashCode_instance;
  }
  function EqualityComparator() {
  }
  EqualityComparator.$metadata$ = {
    simpleName: 'EqualityComparator',
    kind: 'interface',
    interfaces: []
  };
  function EntrySet($outer) {
    this._$this_1 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet.prototype.add_12 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet.prototype.add_16 = function (element) {
    return this.add_12((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet.prototype.containsEntry_4 = function (element) {
    return this._$this_1.containsEntry_5(element);
  };
  EntrySet.prototype.iterator_40 = function () {
    return this._$this_1._internalMap.iterator_40();
  };
  EntrySet.prototype._get_size__32 = function () {
    return this._$this_1._get_size__32();
  };
  EntrySet.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function HashMap_init_$Init$(internalMap, $this) {
    AbstractMutableMap.call($this);
    HashMap.call($this);
    $this._internalMap = internalMap;
    $this._equality = internalMap._get_equality__0();
    return $this;
  }
  function HashMap_init_$Init$_0($this) {
    HashMap_init_$Init$(new InternalHashCodeMap(HashCode_getInstance()), $this);
    return $this;
  }
  function HashMap_init_$Create$() {
    return HashMap_init_$Init$_0(Object.create(HashMap.prototype));
  }
  function HashMap_init_$Init$_1(initialCapacity, loadFactor, $this) {
    HashMap_init_$Init$_0($this);
    var tmp0_require_0 = initialCapacity >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Negative initial capacity: ' + initialCapacity;
      throw IllegalArgumentException_init_$Create$(toString_1(message_1));
    }var tmp1_require_0 = loadFactor >= 0;
    if (!tmp1_require_0) {
      var message_1_0 = '' + 'Non-positive load factor: ' + loadFactor;
      throw IllegalArgumentException_init_$Create$(toString_1(message_1_0));
    }return $this;
  }
  function HashMap_init_$Create$_0(initialCapacity, loadFactor) {
    return HashMap_init_$Init$_1(initialCapacity, loadFactor, Object.create(HashMap.prototype));
  }
  HashMap.prototype.containsKey_9 = function (key) {
    return this._internalMap.contains_15(key);
  };
  HashMap.prototype._get_entries__7 = function () {
    if (this.__entries == null) {
      this.__entries = this.createEntrySet_0();
    }return ensureNotNull(this.__entries);
  };
  HashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet(this);
  };
  HashMap.prototype.get_20 = function (key) {
    return this._internalMap.get_20(key);
  };
  HashMap.prototype.put_7 = function (key, value) {
    return this._internalMap.put_7(key, value);
  };
  HashMap.prototype._get_size__32 = function () {
    return this._internalMap._get_size__32();
  };
  function HashMap() {
    this.__entries = null;
  }
  HashMap.$metadata$ = {
    simpleName: 'HashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function HashSet_init_$Init$(initialCapacity, loadFactor, $this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = HashMap_init_$Create$_0(initialCapacity, loadFactor);
    return $this;
  }
  function HashSet_init_$Init$_0(initialCapacity, $this) {
    HashSet_init_$Init$(initialCapacity, 0.0, $this);
    return $this;
  }
  function HashSet_init_$Create$(initialCapacity) {
    return HashSet_init_$Init$_0(initialCapacity, Object.create(HashSet.prototype));
  }
  function HashSet_init_$Init$_1(map_0, $this) {
    AbstractMutableSet.call($this);
    HashSet.call($this);
    $this._map = map_0;
    return $this;
  }
  HashSet.prototype.add_16 = function (element) {
    var old = this._map.put_7(element, this);
    return old == null;
  };
  HashSet.prototype.contains_19 = function (element) {
    return this._map.containsKey_9(element);
  };
  HashSet.prototype.isEmpty_28 = function () {
    return this._map.isEmpty_28();
  };
  HashSet.prototype.iterator_40 = function () {
    return this._map._get_keys__7().iterator_40();
  };
  HashSet.prototype._get_size__32 = function () {
    return this._map._get_size__32();
  };
  function HashSet() {
  }
  HashSet.$metadata$ = {
    simpleName: 'HashSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function computeNext($this) {
    if ($this._chainOrEntry != null ? $this._isChain : false) {
      var tmp0_unsafeCast_0 = $this._chainOrEntry;
      var chainSize = tmp0_unsafeCast_0.length;
      var tmp0_this = $this;
      tmp0_this._itemIndex = tmp0_this._itemIndex + 1 | 0;
      if (tmp0_this._itemIndex < chainSize)
        return 0;
      else {
      }
    }var tmp1_this = $this;
    tmp1_this._keyIndex = tmp1_this._keyIndex + 1 | 0;
    if (tmp1_this._keyIndex < $this._keys.length) {
      $this._chainOrEntry = $this._this$0_7._backingMap[$this._keys[$this._keyIndex]];
      var tmp = $this;
      var tmp_0 = $this._chainOrEntry;
      tmp._isChain = !(tmp_0 == null) ? isArray(tmp_0) : false;
      $this._itemIndex = 0;
      return 0;
    } else {
      {
        $this._chainOrEntry = null;
        return 1;
      }
    }
  }
  function getEntry($this, key) {
    var tmp0_elvis_lhs = getChainOrEntryOrNull($this, $this._equality_0.getHashCode_0(key));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var chainOrEntry = tmp;
    if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
      var entry = chainOrEntry;
      if ($this._equality_0.equals_1(entry._get_key__7(), key)) {
        return entry;
      } else {
        return null;
      }
    } else {
      {
        var chain = chainOrEntry;
        return findEntryInChain(chain, $this, key);
      }
    }
  }
  function findEntryInChain(_this_, $this, key) {
    var tmp$ret$0;
    l$ret$1: do {
      var indexedObject = _this_;
      var inductionVariable = 0;
      var last_1 = indexedObject.length;
      while (inductionVariable < last_1) {
        var element_2 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if ($this._equality_0.equals_1(element_2._get_key__7(), key)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function getChainOrEntryOrNull($this, hashCode_1) {
    var chainOrEntry = $this._backingMap[hashCode_1];
    return chainOrEntry === undefined ? null : chainOrEntry;
  }
  function _no_name_provided__14(this$0) {
    this._this$0_7 = this$0;
    this._state = -1;
    this._keys = Object.keys(this._this$0_7._backingMap);
    this._keyIndex = -1;
    this._chainOrEntry = null;
    this._isChain = false;
    this._itemIndex = -1;
    this._lastEntry = null;
  }
  _no_name_provided__14.prototype.hasNext_14 = function () {
    if (this._state === -1)
      this._state = computeNext(this);
    return this._state === 0;
  };
  _no_name_provided__14.prototype.next_16 = function () {
    if (!this.hasNext_14())
      throw NoSuchElementException_init_$Create$();
    var tmp;
    if (this._isChain) {
      var tmp0_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp0_unsafeCast_0[this._itemIndex];
    } else {
      var tmp1_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp1_unsafeCast_0;
    }
    var lastEntry = tmp;
    this._lastEntry = lastEntry;
    this._state = -1;
    return lastEntry;
  };
  _no_name_provided__14.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function InternalHashCodeMap(equality) {
    this._equality_0 = equality;
    this._backingMap = this.createJsMap_0();
    this._size = 0;
  }
  InternalHashCodeMap.prototype._get_equality__0 = function () {
    return this._equality_0;
  };
  InternalHashCodeMap.prototype._get_size__32 = function () {
    return this._size;
  };
  InternalHashCodeMap.prototype.put_7 = function (key, value) {
    var hashCode_1 = this._equality_0.getHashCode_0(key);
    var chainOrEntry = getChainOrEntryOrNull(this, hashCode_1);
    if (chainOrEntry == null) {
      this._backingMap[hashCode_1] = new SimpleEntry(key, value);
    } else {
      if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
        var entry = chainOrEntry;
        if (this._equality_0.equals_1(entry._get_key__7(), key)) {
          return entry.setValue_1(value);
        } else {
          var tmp0_arrayOf_0 = [entry, new SimpleEntry(key, value)];
          this._backingMap[hashCode_1] = tmp0_arrayOf_0;
          var tmp0_this = this;
          var tmp1 = tmp0_this._size;
          tmp0_this._size = tmp1 + 1 | 0;
          Unit_getInstance();
          return null;
        }
      } else {
        {
          var chain = chainOrEntry;
          var entry_0 = findEntryInChain(chain, this, key);
          if (!(entry_0 == null)) {
            return entry_0.setValue_1(value);
          }chain.push(new SimpleEntry(key, value));
        }
      }
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this._size;
    tmp2_this._size = tmp3 + 1 | 0;
    Unit_getInstance();
    return null;
  };
  InternalHashCodeMap.prototype.contains_15 = function (key) {
    return !(getEntry(this, key) == null);
  };
  InternalHashCodeMap.prototype.get_20 = function (key) {
    var tmp0_safe_receiver = getEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__8();
  };
  InternalHashCodeMap.prototype.iterator_40 = function () {
    return new _no_name_provided__14(this);
  };
  InternalHashCodeMap.$metadata$ = {
    simpleName: 'InternalHashCodeMap',
    kind: 'class',
    interfaces: [InternalMap]
  };
  function InternalMap() {
  }
  InternalMap.prototype.createJsMap_0 = function () {
    var result = Object.create(null);
    result['foo'] = 1;
    jsDeleteProperty(result, 'foo');
    return result;
  };
  InternalMap.$metadata$ = {
    simpleName: 'InternalMap',
    kind: 'interface',
    interfaces: [MutableIterable]
  };
  function EntryIterator($outer) {
    this._$this_2 = $outer;
    this._last_1 = null;
    this._next_0 = null;
    this._next_0 = this._$this_2._$this_4._head;
  }
  EntryIterator.prototype.hasNext_14 = function () {
    return !(this._next_0 === null);
  };
  EntryIterator.prototype.next_16 = function () {
    if (!this.hasNext_14())
      throw NoSuchElementException_init_$Create$();
    var current = ensureNotNull(this._next_0);
    this._last_1 = current;
    var tmp = this;
    var tmp0_takeIf_0 = current._next_1;
    var tmp_0;
    if (!(tmp0_takeIf_0 === this._$this_2._$this_4._head)) {
      tmp_0 = tmp0_takeIf_0;
    } else {
      {
        tmp_0 = null;
      }
    }
    tmp._next_0 = tmp_0;
    return current;
  };
  EntryIterator.$metadata$ = {
    simpleName: 'EntryIterator',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ChainEntry($outer, key, value) {
    this._$this_3 = $outer;
    SimpleEntry.call(this, key, value);
    this._next_1 = null;
    this._prev = null;
  }
  ChainEntry.prototype.setValue_1 = function (newValue) {
    this._$this_3.checkIsMutable_4();
    return SimpleEntry.prototype.setValue_1.call(this, newValue);
  };
  ChainEntry.$metadata$ = {
    simpleName: 'ChainEntry',
    kind: 'class',
    interfaces: []
  };
  function EntrySet_0($outer) {
    this._$this_4 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet_0.prototype.add_12 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet_0.prototype.add_16 = function (element) {
    return this.add_12((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet_0.prototype.containsEntry_4 = function (element) {
    return this._$this_4.containsEntry_5(element);
  };
  EntrySet_0.prototype.iterator_40 = function () {
    return new EntryIterator(this);
  };
  EntrySet_0.prototype._get_size__32 = function () {
    return this._$this_4._get_size__32();
  };
  EntrySet_0.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function addToEnd(_this_, $this) {
    var tmp0_check_0 = _this_._next_1 == null ? _this_._prev == null : false;
    if (!tmp0_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString_1(message_2_1));
    }var _head = $this._head;
    if (_head == null) {
      $this._head = _this_;
      _this_._next_1 = _this_;
      _this_._prev = _this_;
    } else {
      var tmp1_checkNotNull_0 = _head._prev;
      var tmp$ret$0;
      l$ret$1: do {
        if (tmp1_checkNotNull_0 == null) {
          var message_2_1_0 = 'Required value was null.';
          throw IllegalStateException_init_$Create$(toString_1(message_2_1_0));
        } else {
          tmp$ret$0 = tmp1_checkNotNull_0;
          break l$ret$1;
        }
      }
       while (false);
      var _tail = tmp$ret$0;
      _this_._prev = _tail;
      _this_._next_1 = _head;
      _head._prev = _this_;
      _tail._next_1 = _this_;
    }
  }
  function LinkedHashMap_init_$Init$($this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$() {
    return LinkedHashMap_init_$Init$(Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_0(initialCapacity, loadFactor, $this) {
    HashMap_init_$Init$_1(initialCapacity, loadFactor, $this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$_0(initialCapacity, loadFactor) {
    return LinkedHashMap_init_$Init$_0(initialCapacity, loadFactor, Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_1(initialCapacity, $this) {
    LinkedHashMap_init_$Init$_0(initialCapacity, 0.0, $this);
    return $this;
  }
  function LinkedHashMap_init_$Create$_1(initialCapacity) {
    return LinkedHashMap_init_$Init$_1(initialCapacity, Object.create(LinkedHashMap.prototype));
  }
  function LinkedHashMap_init_$Init$_2(original, $this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map_0 = HashMap_init_$Create$();
    $this.putAll_0(original);
    return $this;
  }
  function LinkedHashMap_init_$Create$_2(original) {
    return LinkedHashMap_init_$Init$_2(original, Object.create(LinkedHashMap.prototype));
  }
  LinkedHashMap.prototype.containsKey_9 = function (key) {
    return this._map_0.containsKey_9(key);
  };
  LinkedHashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet_0(this);
  };
  LinkedHashMap.prototype.get_20 = function (key) {
    var tmp0_safe_receiver = this._map_0.get_20(key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__8();
  };
  LinkedHashMap.prototype.put_7 = function (key, value) {
    this.checkIsMutable_4();
    var old = this._map_0.get_20(key);
    if (old == null) {
      var newEntry = new ChainEntry(this, key, value);
      this._map_0.put_7(key, newEntry);
      Unit_getInstance();
      addToEnd(newEntry, this);
      return null;
    } else {
      return old.setValue_1(value);
    }
  };
  LinkedHashMap.prototype._get_size__32 = function () {
    return this._map_0._get_size__32();
  };
  LinkedHashMap.prototype.checkIsMutable_4 = function () {
    if (this._isReadOnly_0)
      throw UnsupportedOperationException_init_$Create$();
  };
  function LinkedHashMap() {
    this._head = null;
    this._isReadOnly_0 = false;
  }
  LinkedHashMap.$metadata$ = {
    simpleName: 'LinkedHashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function LinkedHashSet_init_$Init$($this) {
    HashSet_init_$Init$_1(LinkedHashMap_init_$Create$(), $this);
    LinkedHashSet.call($this);
    return $this;
  }
  function LinkedHashSet_init_$Create$() {
    return LinkedHashSet_init_$Init$(Object.create(LinkedHashSet.prototype));
  }
  function LinkedHashSet_init_$Init$_0(initialCapacity, loadFactor, $this) {
    HashSet_init_$Init$_1(LinkedHashMap_init_$Create$_0(initialCapacity, loadFactor), $this);
    LinkedHashSet.call($this);
    return $this;
  }
  function LinkedHashSet_init_$Init$_1(initialCapacity, $this) {
    LinkedHashSet_init_$Init$_0(initialCapacity, 0.0, $this);
    return $this;
  }
  function LinkedHashSet_init_$Create$_0(initialCapacity) {
    return LinkedHashSet_init_$Init$_1(initialCapacity, Object.create(LinkedHashSet.prototype));
  }
  function LinkedHashSet() {
  }
  LinkedHashSet.$metadata$ = {
    simpleName: 'LinkedHashSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function RandomAccess() {
  }
  RandomAccess.$metadata$ = {
    simpleName: 'RandomAccess',
    kind: 'interface',
    interfaces: []
  };
  function Serializable() {
  }
  Serializable.$metadata$ = {
    simpleName: 'Serializable',
    kind: 'interface',
    interfaces: []
  };
  function isNaN_0(_this_) {
    return !(_this_ === _this_);
  }
  function _get_js_(_this_) {
    return (_this_ instanceof KClassImpl ? _this_ : THROW_CCE())._get_jClass__2();
  }
  function KClass() {
  }
  KClass.$metadata$ = {
    simpleName: 'KClass',
    kind: 'interface',
    interfaces: [KClassifier]
  };
  function KClassImpl(jClass) {
    this._jClass = jClass;
  }
  KClassImpl.prototype._get_jClass__2 = function () {
    return this._jClass;
  };
  KClassImpl.prototype.equals = function (other) {
    var tmp;
    if (other instanceof KClassImpl) {
      tmp = equals_0(this._get_jClass__2(), other._get_jClass__2());
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  KClassImpl.prototype.hashCode = function () {
    var tmp0_safe_receiver = this._get_simpleName__2();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : getStringHashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  KClassImpl.prototype.toString = function () {
    return '' + 'class ' + this._get_simpleName__2();
  };
  KClassImpl.$metadata$ = {
    simpleName: 'KClassImpl',
    kind: 'class',
    interfaces: [KClass]
  };
  function PrimitiveKClassImpl(jClass, givenSimpleName, isInstanceFunction) {
    KClassImpl.call(this, jClass);
    this._givenSimpleName = givenSimpleName;
    this._isInstanceFunction = isInstanceFunction;
  }
  PrimitiveKClassImpl.prototype.equals = function (other) {
    if (!(other instanceof PrimitiveKClassImpl))
      return false;
    else {
    }
    return KClassImpl.prototype.equals.call(this, other) ? this._givenSimpleName === other._givenSimpleName : false;
  };
  PrimitiveKClassImpl.prototype._get_simpleName__2 = function () {
    return this._givenSimpleName;
  };
  PrimitiveKClassImpl.$metadata$ = {
    simpleName: 'PrimitiveKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function NothingKClassImpl() {
    NothingKClassImpl_instance = this;
    KClassImpl.call(this, Object);
    this._simpleName = 'Nothing';
  }
  NothingKClassImpl.prototype._get_simpleName__2 = function () {
    return this._simpleName;
  };
  NothingKClassImpl.prototype._get_jClass__2 = function () {
    throw UnsupportedOperationException_init_$Create$_0("There's no native JS class for Nothing type");
  };
  NothingKClassImpl.prototype.equals = function (other) {
    return other === this;
  };
  NothingKClassImpl.prototype.hashCode = function () {
    return 0;
  };
  NothingKClassImpl.$metadata$ = {
    simpleName: 'NothingKClassImpl',
    kind: 'object',
    interfaces: []
  };
  var NothingKClassImpl_instance;
  function NothingKClassImpl_getInstance() {
    if (NothingKClassImpl_instance == null)
      new NothingKClassImpl();
    return NothingKClassImpl_instance;
  }
  function ErrorKClass() {
  }
  ErrorKClass.prototype.equals = function (other) {
    return other === this;
  };
  ErrorKClass.prototype.hashCode = function () {
    return 0;
  };
  ErrorKClass.$metadata$ = {
    simpleName: 'ErrorKClass',
    kind: 'class',
    interfaces: [KClass]
  };
  function SimpleKClassImpl(jClass) {
    KClassImpl.call(this, jClass);
    var tmp = this;
    var tmp0_safe_receiver = jClass.$metadata$;
    var tmp0_unsafeCast_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.simpleName;
    tmp._simpleName_0 = tmp0_unsafeCast_0;
  }
  SimpleKClassImpl.prototype._get_simpleName__2 = function () {
    return this._simpleName_0;
  };
  SimpleKClassImpl.$metadata$ = {
    simpleName: 'SimpleKClassImpl',
    kind: 'class',
    interfaces: []
  };
  var functionClasses;
  function _no_name_provided__15() {
  }
  _no_name_provided__15.prototype.invoke_58 = function (it) {
    return isObject(it);
  };
  _no_name_provided__15.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__15.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__16() {
  }
  _no_name_provided__16.prototype.invoke_58 = function (it) {
    return isNumber(it);
  };
  _no_name_provided__16.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__16.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__17() {
  }
  _no_name_provided__17.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'boolean' : false;
  };
  _no_name_provided__17.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__17.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__18() {
  }
  _no_name_provided__18.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__18.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__18.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__19() {
  }
  _no_name_provided__19.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__19.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__19.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__20() {
  }
  _no_name_provided__20.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__20.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__20.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__21() {
  }
  _no_name_provided__21.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__21.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__21.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__22() {
  }
  _no_name_provided__22.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__22.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__22.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__23() {
  }
  _no_name_provided__23.prototype.invoke_58 = function (it) {
    return !(it == null) ? isArray(it) : false;
  };
  _no_name_provided__23.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__23.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__24() {
  }
  _no_name_provided__24.prototype.invoke_58 = function (it) {
    return !(it == null) ? typeof it === 'string' : false;
  };
  _no_name_provided__24.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__24.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__25() {
  }
  _no_name_provided__25.prototype.invoke_58 = function (it) {
    return it instanceof Error;
  };
  _no_name_provided__25.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__25.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__26() {
  }
  _no_name_provided__26.prototype.invoke_58 = function (it) {
    return !(it == null) ? isBooleanArray(it) : false;
  };
  _no_name_provided__26.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__26.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__27() {
  }
  _no_name_provided__27.prototype.invoke_58 = function (it) {
    return !(it == null) ? isCharArray(it) : false;
  };
  _no_name_provided__27.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__27.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__28() {
  }
  _no_name_provided__28.prototype.invoke_58 = function (it) {
    return !(it == null) ? isByteArray(it) : false;
  };
  _no_name_provided__28.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__28.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__29() {
  }
  _no_name_provided__29.prototype.invoke_58 = function (it) {
    return !(it == null) ? isShortArray(it) : false;
  };
  _no_name_provided__29.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__29.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__30() {
  }
  _no_name_provided__30.prototype.invoke_58 = function (it) {
    return !(it == null) ? isIntArray(it) : false;
  };
  _no_name_provided__30.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__30.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__31() {
  }
  _no_name_provided__31.prototype.invoke_58 = function (it) {
    return !(it == null) ? isLongArray(it) : false;
  };
  _no_name_provided__31.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__31.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__32() {
  }
  _no_name_provided__32.prototype.invoke_58 = function (it) {
    return !(it == null) ? isFloatArray(it) : false;
  };
  _no_name_provided__32.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__32.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__33() {
  }
  _no_name_provided__33.prototype.invoke_58 = function (it) {
    return !(it == null) ? isDoubleArray(it) : false;
  };
  _no_name_provided__33.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__33.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__34($arity) {
    this._$arity = $arity;
  }
  _no_name_provided__34.prototype.invoke_58 = function (it) {
    var tmp;
    if (typeof it === 'function') {
      tmp = it.length === this._$arity;
    } else {
      tmp = false;
    }
    return tmp;
  };
  _no_name_provided__34.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__34.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function PrimitiveClasses_0() {
    PrimitiveClasses_instance = this;
    var tmp = this;
    var tmp0_unsafeCast_0 = Object;
    var tmp_0 = tmp0_unsafeCast_0;
    tmp._anyClass = new PrimitiveKClassImpl(tmp_0, 'Any', _no_name_provided_$factory_5());
    var tmp_1 = this;
    var tmp0_unsafeCast_0_0 = Number;
    var tmp_2 = tmp0_unsafeCast_0_0;
    tmp_1._numberClass = new PrimitiveKClassImpl(tmp_2, 'Number', _no_name_provided_$factory_6());
    this._nothingClass = NothingKClassImpl_getInstance();
    var tmp_3 = this;
    var tmp0_unsafeCast_0_1 = Boolean;
    var tmp_4 = tmp0_unsafeCast_0_1;
    tmp_3._booleanClass = new PrimitiveKClassImpl(tmp_4, 'Boolean', _no_name_provided_$factory_7());
    var tmp_5 = this;
    var tmp0_unsafeCast_0_2 = Number;
    var tmp_6 = tmp0_unsafeCast_0_2;
    tmp_5._byteClass = new PrimitiveKClassImpl(tmp_6, 'Byte', _no_name_provided_$factory_8());
    var tmp_7 = this;
    var tmp0_unsafeCast_0_3 = Number;
    var tmp_8 = tmp0_unsafeCast_0_3;
    tmp_7._shortClass = new PrimitiveKClassImpl(tmp_8, 'Short', _no_name_provided_$factory_9());
    var tmp_9 = this;
    var tmp0_unsafeCast_0_4 = Number;
    var tmp_10 = tmp0_unsafeCast_0_4;
    tmp_9._intClass = new PrimitiveKClassImpl(tmp_10, 'Int', _no_name_provided_$factory_10());
    var tmp_11 = this;
    var tmp0_unsafeCast_0_5 = Number;
    var tmp_12 = tmp0_unsafeCast_0_5;
    tmp_11._floatClass = new PrimitiveKClassImpl(tmp_12, 'Float', _no_name_provided_$factory_11());
    var tmp_13 = this;
    var tmp0_unsafeCast_0_6 = Number;
    var tmp_14 = tmp0_unsafeCast_0_6;
    tmp_13._doubleClass = new PrimitiveKClassImpl(tmp_14, 'Double', _no_name_provided_$factory_12());
    var tmp_15 = this;
    var tmp0_unsafeCast_0_7 = Array;
    var tmp_16 = tmp0_unsafeCast_0_7;
    tmp_15._arrayClass = new PrimitiveKClassImpl(tmp_16, 'Array', _no_name_provided_$factory_13());
    var tmp_17 = this;
    var tmp0_unsafeCast_0_8 = String;
    var tmp_18 = tmp0_unsafeCast_0_8;
    tmp_17._stringClass = new PrimitiveKClassImpl(tmp_18, 'String', _no_name_provided_$factory_14());
    var tmp_19 = this;
    var tmp0_unsafeCast_0_9 = Error;
    var tmp_20 = tmp0_unsafeCast_0_9;
    tmp_19._throwableClass = new PrimitiveKClassImpl(tmp_20, 'Throwable', _no_name_provided_$factory_15());
    var tmp_21 = this;
    var tmp0_unsafeCast_0_10 = Array;
    var tmp_22 = tmp0_unsafeCast_0_10;
    tmp_21._booleanArrayClass = new PrimitiveKClassImpl(tmp_22, 'BooleanArray', _no_name_provided_$factory_16());
    var tmp_23 = this;
    var tmp0_unsafeCast_0_11 = Uint16Array;
    var tmp_24 = tmp0_unsafeCast_0_11;
    tmp_23._charArrayClass = new PrimitiveKClassImpl(tmp_24, 'CharArray', _no_name_provided_$factory_17());
    var tmp_25 = this;
    var tmp0_unsafeCast_0_12 = Int8Array;
    var tmp_26 = tmp0_unsafeCast_0_12;
    tmp_25._byteArrayClass = new PrimitiveKClassImpl(tmp_26, 'ByteArray', _no_name_provided_$factory_18());
    var tmp_27 = this;
    var tmp0_unsafeCast_0_13 = Int16Array;
    var tmp_28 = tmp0_unsafeCast_0_13;
    tmp_27._shortArrayClass = new PrimitiveKClassImpl(tmp_28, 'ShortArray', _no_name_provided_$factory_19());
    var tmp_29 = this;
    var tmp0_unsafeCast_0_14 = Int32Array;
    var tmp_30 = tmp0_unsafeCast_0_14;
    tmp_29._intArrayClass = new PrimitiveKClassImpl(tmp_30, 'IntArray', _no_name_provided_$factory_20());
    var tmp_31 = this;
    var tmp0_unsafeCast_0_15 = Array;
    var tmp_32 = tmp0_unsafeCast_0_15;
    tmp_31._longArrayClass = new PrimitiveKClassImpl(tmp_32, 'LongArray', _no_name_provided_$factory_21());
    var tmp_33 = this;
    var tmp0_unsafeCast_0_16 = Float32Array;
    var tmp_34 = tmp0_unsafeCast_0_16;
    tmp_33._floatArrayClass = new PrimitiveKClassImpl(tmp_34, 'FloatArray', _no_name_provided_$factory_22());
    var tmp_35 = this;
    var tmp0_unsafeCast_0_17 = Float64Array;
    var tmp_36 = tmp0_unsafeCast_0_17;
    tmp_35._doubleArrayClass = new PrimitiveKClassImpl(tmp_36, 'DoubleArray', _no_name_provided_$factory_23());
  }
  PrimitiveClasses_0.prototype._get_anyClass_ = function () {
    return this._anyClass;
  };
  PrimitiveClasses_0.prototype._get_numberClass_ = function () {
    return this._numberClass;
  };
  PrimitiveClasses_0.prototype._get_nothingClass_ = function () {
    return this._nothingClass;
  };
  PrimitiveClasses_0.prototype._get_booleanClass_ = function () {
    return this._booleanClass;
  };
  PrimitiveClasses_0.prototype._get_byteClass_ = function () {
    return this._byteClass;
  };
  PrimitiveClasses_0.prototype._get_shortClass_ = function () {
    return this._shortClass;
  };
  PrimitiveClasses_0.prototype._get_intClass_ = function () {
    return this._intClass;
  };
  PrimitiveClasses_0.prototype._get_floatClass_ = function () {
    return this._floatClass;
  };
  PrimitiveClasses_0.prototype._get_doubleClass_ = function () {
    return this._doubleClass;
  };
  PrimitiveClasses_0.prototype._get_arrayClass_ = function () {
    return this._arrayClass;
  };
  PrimitiveClasses_0.prototype._get_stringClass_ = function () {
    return this._stringClass;
  };
  PrimitiveClasses_0.prototype._get_throwableClass_ = function () {
    return this._throwableClass;
  };
  PrimitiveClasses_0.prototype._get_booleanArrayClass_ = function () {
    return this._booleanArrayClass;
  };
  PrimitiveClasses_0.prototype._get_charArrayClass_ = function () {
    return this._charArrayClass;
  };
  PrimitiveClasses_0.prototype._get_byteArrayClass_ = function () {
    return this._byteArrayClass;
  };
  PrimitiveClasses_0.prototype._get_shortArrayClass_ = function () {
    return this._shortArrayClass;
  };
  PrimitiveClasses_0.prototype._get_intArrayClass_ = function () {
    return this._intArrayClass;
  };
  PrimitiveClasses_0.prototype._get_longArrayClass_ = function () {
    return this._longArrayClass;
  };
  PrimitiveClasses_0.prototype._get_floatArrayClass_ = function () {
    return this._floatArrayClass;
  };
  PrimitiveClasses_0.prototype._get_doubleArrayClass_ = function () {
    return this._doubleArrayClass;
  };
  PrimitiveClasses_0.prototype.functionClass = function (arity) {
    var tmp0_elvis_lhs = functionClasses[arity];
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_unsafeCast_0_3 = Function;
      var tmp_0 = tmp0_unsafeCast_0_3;
      var tmp_1 = '' + 'Function' + arity;
      var result_2 = new PrimitiveKClassImpl(tmp_0, tmp_1, _no_name_provided_$factory_24(arity));
      var tmp1_asDynamic_0_5 = functionClasses;
      tmp1_asDynamic_0_5[arity] = result_2;
      tmp = result_2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  PrimitiveClasses_0.$metadata$ = {
    simpleName: 'PrimitiveClasses',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(PrimitiveClasses_0.prototype, 'anyClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_anyClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'numberClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_numberClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'nothingClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_nothingClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'arrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_arrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'stringClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_stringClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'throwableClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_throwableClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'charArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_charArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'longArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_longArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleArrayClass_
  });
  var PrimitiveClasses_instance;
  function PrimitiveClasses_getInstance() {
    if (PrimitiveClasses_instance == null)
      new PrimitiveClasses_0();
    return PrimitiveClasses_instance;
  }
  function _no_name_provided_$factory_5() {
    var i = new _no_name_provided__15();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_6() {
    var i = new _no_name_provided__16();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_7() {
    var i = new _no_name_provided__17();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_8() {
    var i = new _no_name_provided__18();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_9() {
    var i = new _no_name_provided__19();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_10() {
    var i = new _no_name_provided__20();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_11() {
    var i = new _no_name_provided__21();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_12() {
    var i = new _no_name_provided__22();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_13() {
    var i = new _no_name_provided__23();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_14() {
    var i = new _no_name_provided__24();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_15() {
    var i = new _no_name_provided__25();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_16() {
    var i = new _no_name_provided__26();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_17() {
    var i = new _no_name_provided__27();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_18() {
    var i = new _no_name_provided__28();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_19() {
    var i = new _no_name_provided__29();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_20() {
    var i = new _no_name_provided__30();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_21() {
    var i = new _no_name_provided__31();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_22() {
    var i = new _no_name_provided__32();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_23() {
    var i = new _no_name_provided__33();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function _no_name_provided_$factory_24($arity) {
    var i = new _no_name_provided__34($arity);
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function functionClasses$init$() {
    var tmp0_arrayOfNulls_0 = 0;
    return fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
  }
  function getKClass_0(jClass) {
    var tmp;
    if (Array.isArray(jClass)) {
      tmp = getKClassM_0(jClass);
    } else {
      tmp = getKClass1_0(jClass);
    }
    return tmp;
  }
  function getKClassM_0(jClasses) {
    var tmp0_subject = jClasses.length;
    var tmp;
    switch (tmp0_subject) {
      case 1:
        tmp = getKClass1_0(jClasses[0]);
        break;
      case 0:
        var tmp0_unsafeCast_0 = NothingKClassImpl_getInstance();
        tmp = tmp0_unsafeCast_0;
        break;
      default:var tmp1_unsafeCast_0 = new ErrorKClass();
        tmp = tmp1_unsafeCast_0;
        break;
    }
    return tmp;
  }
  function getKClass1_0(jClass) {
    if (jClass === String) {
      var tmp0_unsafeCast_0 = PrimitiveClasses_getInstance()._stringClass;
      return tmp0_unsafeCast_0;
    }var metadata = jClass.$metadata$;
    var tmp;
    if (metadata != null) {
      var tmp_0;
      if (metadata.$kClass$ == null) {
        var kClass = new SimpleKClassImpl(jClass);
        metadata.$kClass$ = kClass;
        tmp_0 = kClass;
      } else {
        tmp_0 = metadata.$kClass$;
      }
      tmp = tmp_0;
    } else {
      tmp = new SimpleKClassImpl(jClass);
    }
    return tmp;
  }
  function Appendable() {
  }
  Appendable.$metadata$ = {
    simpleName: 'Appendable',
    kind: 'interface',
    interfaces: []
  };
  function StringBuilder_init_$Init$(capacity, $this) {
    StringBuilder_init_$Init$_0($this);
    return $this;
  }
  function StringBuilder_init_$Create$(capacity) {
    return StringBuilder_init_$Init$(capacity, Object.create(StringBuilder.prototype));
  }
  function StringBuilder_init_$Init$_0($this) {
    StringBuilder.call($this, '');
    return $this;
  }
  function StringBuilder_init_$Create$_0() {
    return StringBuilder_init_$Init$_0(Object.create(StringBuilder.prototype));
  }
  function StringBuilder(content) {
    this._string = !(content === undefined) ? content : '';
  }
  StringBuilder.prototype._get_length__31 = function () {
    var tmp0_asDynamic_0 = this._string;
    return tmp0_asDynamic_0.length;
  };
  StringBuilder.prototype.get_30 = function (index) {
    var tmp0_getOrElse_0 = this._string;
    var tmp;
    if (index >= 0 ? index <= _get_lastIndex__0(tmp0_getOrElse_0) : false) {
      tmp = charSequenceGet(tmp0_getOrElse_0, index);
    } else {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', length: ' + this._get_length__31() + '}');
    }
    return tmp;
  };
  StringBuilder.prototype.subSequence_1 = function (startIndex, endIndex) {
    var tmp0_substring_0 = this._string;
    return tmp0_substring_0.substring(startIndex, endIndex);
  };
  StringBuilder.prototype.append_2 = function (value) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + value;
    return this;
  };
  StringBuilder.prototype.append_3 = function (value) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + toString_0(value);
    return this;
  };
  StringBuilder.prototype.append_4 = function (value) {
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp_0 = tmp0_this._string;
    var tmp1_elvis_lhs = value;
    tmp._string = tmp_0 + (tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs);
    return this;
  };
  StringBuilder.prototype.toString = function () {
    return this._string;
  };
  StringBuilder.$metadata$ = {
    simpleName: 'StringBuilder',
    kind: 'class',
    interfaces: [Appendable, CharSequence]
  };
  function isWhitespace(_this_) {
    return isWhitespaceImpl(_this_);
  }
  function uppercaseChar(_this_) {
    var tmp0_asDynamic_0 = _this_.toString();
    var tmp1_unsafeCast_0 = tmp0_asDynamic_0.toUpperCase();
    var uppercase = tmp1_unsafeCast_0;
    return uppercase.length > 1 ? _this_ : charSequenceGet(uppercase, 0);
  }
  function Regex_init_$Init$(pattern, $this) {
    Regex.call($this, pattern, emptySet());
    return $this;
  }
  function Regex_init_$Create$(pattern) {
    return Regex_init_$Init$(pattern, Object.create(Regex.prototype));
  }
  function Companion_5() {
    Companion_instance_4 = this;
    this._patternEscape = new RegExp('[\\\\^$*+?.()|[\\]{}]', 'g');
    this._replacementEscape = new RegExp('\\$', 'g');
  }
  Companion_5.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    if (Companion_instance_4 == null)
      new Companion_5();
    return Companion_instance_4;
  }
  function _no_name_provided__35() {
  }
  _no_name_provided__35.prototype.invoke_51 = function (it) {
    return it._value;
  };
  _no_name_provided__35.prototype.invoke_84 = function (p1) {
    return this.invoke_51(p1 instanceof RegexOption ? p1 : THROW_CCE());
  };
  _no_name_provided__35.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__36(this$0, $input, $startIndex) {
    this._this$0_8 = this$0;
    this._$input = $input;
    this._$startIndex = $startIndex;
  }
  _no_name_provided__36.prototype.invoke_62 = function () {
    return this._this$0_8.find(this._$input, this._$startIndex);
  };
  _no_name_provided__36.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__37() {
  }
  _no_name_provided__37.prototype.invoke_54 = function (match) {
    return match.next_16();
  };
  _no_name_provided__37.prototype.invoke_84 = function (p1) {
    return this.invoke_54((!(p1 == null) ? isInterface(p1, MatchResult) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__37.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Regex(pattern, options) {
    Companion_getInstance_4();
    this._pattern = pattern;
    this._options = toSet(options);
    var tmp = this;
    tmp._nativePattern = new RegExp(pattern, joinToString$default_0(options, '', 'gu', null, 0, null, _no_name_provided_$factory_25(), 28, null));
  }
  Regex.prototype.find = function (input, startIndex) {
    if (startIndex < 0 ? true : startIndex > charSequenceLength(input)) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'Start index out of bounds: ' + startIndex + ', input length: ' + charSequenceLength(input));
    }return findNext(this._nativePattern, toString_1(input), startIndex);
  };
  Regex.prototype.findAll = function (input, startIndex) {
    if (startIndex < 0 ? true : startIndex > charSequenceLength(input)) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'Start index out of bounds: ' + startIndex + ', input length: ' + charSequenceLength(input));
    }var tmp = _no_name_provided_$factory_26(this, input, startIndex);
    return generateSequence(tmp, _no_name_provided_$factory_27());
  };
  Regex.prototype.findAll$default = function (input, startIndex, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      startIndex = 0;
    return this.findAll(input, startIndex);
  };
  Regex.prototype.split = function (input, limit) {
    var tmp0_require_0 = limit >= 0;
    if (!tmp0_require_0) {
      var message_1 = '' + 'Limit must be non-negative, but was ' + limit;
      throw IllegalArgumentException_init_$Create$(toString_1(message_1));
    }var tmp1_let_0 = this.findAll$default(input, 0, 2, null);
    var matches_0 = limit === 0 ? tmp1_let_0 : take(tmp1_let_0, limit - 1 | 0);
    var result = ArrayList_init_$Create$();
    var lastStart = 0;
    var tmp0_iterator = matches_0.iterator_40();
    while (tmp0_iterator.hasNext_14()) {
      var match = tmp0_iterator.next_16();
      result.add_16(toString_1(charSequenceSubSequence(input, lastStart, match._get_range__0()._get_start_())));
      Unit_getInstance();
      lastStart = match._get_range__0()._get_endInclusive_() + 1 | 0;
    }
    result.add_16(toString_1(charSequenceSubSequence(input, lastStart, charSequenceLength(input))));
    Unit_getInstance();
    return result;
  };
  Regex.prototype.toString = function () {
    return this._nativePattern.toString();
  };
  Regex.$metadata$ = {
    simpleName: 'Regex',
    kind: 'class',
    interfaces: []
  };
  function RegexOption() {
  }
  RegexOption.$metadata$ = {
    simpleName: 'RegexOption',
    kind: 'class',
    interfaces: []
  };
  function findNext(_this_, input, from) {
    _this_.lastIndex = from;
    var match = _this_.exec(input);
    if (match == null)
      return null;
    var range = numberRangeToNumber(match.index, _this_.lastIndex - 1 | 0);
    return new _no_name_provided__40(range, match, _this_, input);
  }
  function MatchGroup(value) {
    this._value_0 = value;
  }
  MatchGroup.prototype.toString = function () {
    return '' + 'MatchGroup(value=' + this._value_0 + ')';
  };
  MatchGroup.prototype.hashCode = function () {
    return getStringHashCode(this._value_0);
  };
  MatchGroup.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MatchGroup))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof MatchGroup ? other : THROW_CCE();
    if (!(this._value_0 === tmp0_other_with_cast._value_0))
      return false;
    return true;
  };
  MatchGroup.$metadata$ = {
    simpleName: 'MatchGroup',
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__38(this$0) {
    this._this$0_9 = this$0;
  }
  _no_name_provided__38.prototype.invoke_56 = function (it) {
    return this._this$0_9.get_30(it);
  };
  _no_name_provided__38.prototype.invoke_84 = function (p1) {
    return this.invoke_56((!(p1 == null) ? typeof p1 === 'number' : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__38.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__39($match) {
    this._$match = $match;
    AbstractCollection.call(this);
  }
  _no_name_provided__39.prototype._get_size__32 = function () {
    return this._$match.length;
  };
  _no_name_provided__39.prototype.iterator_40 = function () {
    var tmp = asSequence(_get_indices_(this));
    return map(tmp, _no_name_provided_$factory_28(this)).iterator_40();
  };
  _no_name_provided__39.prototype.get_30 = function (index) {
    var tmp0_safe_receiver = this._$match[index];
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = new MatchGroup(tmp0_safe_receiver);
    }
    return tmp;
  };
  _no_name_provided__39.$metadata$ = {
    kind: 'class',
    interfaces: [MatchGroupCollection]
  };
  function _no_name_provided__40($range, $match, $this_findNext, $input) {
    this._$range = $range;
    this._$match_0 = $match;
    this._$this_findNext = $this_findNext;
    this._$input_0 = $input;
    this._range = this._$range;
    var tmp = this;
    tmp._groups = new _no_name_provided__39(this._$match_0);
    this._groupValues_ = null;
  }
  _no_name_provided__40.prototype._get_range__0 = function () {
    return this._range;
  };
  _no_name_provided__40.prototype.next_16 = function () {
    return findNext(this._$this_findNext, this._$input_0, this._$range.isEmpty_28() ? this._$range._get_start_() + 1 | 0 : this._$range._get_endInclusive_() + 1 | 0);
  };
  _no_name_provided__40.$metadata$ = {
    kind: 'class',
    interfaces: [MatchResult]
  };
  function _no_name_provided_$factory_25() {
    var i = new _no_name_provided__35();
    return function (p1) {
      return i.invoke_51(p1);
    };
  }
  function _no_name_provided_$factory_26(this$0, $input, $startIndex) {
    var i = new _no_name_provided__36(this$0, $input, $startIndex);
    return function () {
      return i.invoke_62();
    };
  }
  function _no_name_provided_$factory_27() {
    var i = new _no_name_provided__37();
    return function (p1) {
      return i.invoke_54(p1);
    };
  }
  function _no_name_provided_$factory_28(this$0) {
    var i = new _no_name_provided__38(this$0);
    return function (p1) {
      return i.invoke_56(p1);
    };
  }
  function regionMatches(_this_, thisOffset, other, otherOffset, length, ignoreCase) {
    return regionMatchesImpl(_this_, thisOffset, other, otherOffset, length, ignoreCase);
  }
  function isBlank(_this_) {
    var tmp;
    if (charSequenceLength(_this_) === 0) {
      tmp = true;
    } else {
      var tmp_0;
      if (typeof _this_ === 'string') {
        tmp_0 = _this_;
      } else {
        {
          tmp_0 = toString_1(_this_);
        }
      }
      tmp = matches(tmp_0, '^[\\s\\xA0]+$');
    }
    return tmp;
  }
  function matches(_this_, regex) {
    var result = _this_.match(regex);
    return !(result == null) ? !(result.length === 0) : false;
  }
  function Companion_6() {
    Companion_instance_5 = this;
    this._MIN_VALUE_0 = new Char(0);
    this._MAX_VALUE_0 = new Char(65535);
    this._MIN_HIGH_SURROGATE = new Char(55296);
    this._MAX_HIGH_SURROGATE = new Char(56319);
    this._MIN_LOW_SURROGATE = new Char(56320);
    this._MAX_LOW_SURROGATE = new Char(57343);
    this._MIN_SURROGATE = new Char(55296);
    this._MAX_SURROGATE = new Char(57343);
    this._SIZE_BYTES_0 = 2;
    this._SIZE_BITS_0 = 16;
  }
  Companion_6.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null)
      new Companion_6();
    return Companion_instance_5;
  }
  function Char(code) {
    Companion_getInstance_5();
    var tmp = this;
    tmp._value_1 = _UShort___get_data__impl_(code) & 65535;
  }
  Char.prototype.compareTo_0 = function (other) {
    return this._value_1 - other._value_1 | 0;
  };
  Char.prototype.compareTo_3 = function (other) {
    return this.compareTo_0(other instanceof Char ? other : THROW_CCE());
  };
  Char.prototype.toInt_5 = function () {
    return this._value_1;
  };
  Char.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(other instanceof Char))
      return false;
    else {
    }
    return this._value_1 === other._value_1;
  };
  Char.prototype.hashCode = function () {
    return this._value_1;
  };
  Char.prototype.toString = function () {
    var tmp0_unsafeCast_0 = String.fromCharCode(this._value_1);
    return tmp0_unsafeCast_0;
  };
  Char.$metadata$ = {
    simpleName: 'Char',
    kind: 'class',
    interfaces: [Comparable]
  };
  function Iterable() {
  }
  Iterable.$metadata$ = {
    simpleName: 'Iterable',
    kind: 'interface',
    interfaces: []
  };
  function Entry() {
  }
  Entry.$metadata$ = {
    simpleName: 'Entry',
    kind: 'interface',
    interfaces: []
  };
  function Map_0() {
  }
  Map_0.$metadata$ = {
    simpleName: 'Map',
    kind: 'interface',
    interfaces: []
  };
  function Set() {
  }
  Set.$metadata$ = {
    simpleName: 'Set',
    kind: 'interface',
    interfaces: [Collection]
  };
  function List() {
  }
  List.$metadata$ = {
    simpleName: 'List',
    kind: 'interface',
    interfaces: [Collection]
  };
  function MutableEntry() {
  }
  MutableEntry.$metadata$ = {
    simpleName: 'MutableEntry',
    kind: 'interface',
    interfaces: [Entry]
  };
  function MutableMap() {
  }
  MutableMap.$metadata$ = {
    simpleName: 'MutableMap',
    kind: 'interface',
    interfaces: [Map_0]
  };
  function Collection() {
  }
  Collection.$metadata$ = {
    simpleName: 'Collection',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function MutableList() {
  }
  MutableList.$metadata$ = {
    simpleName: 'MutableList',
    kind: 'interface',
    interfaces: [List, MutableCollection]
  };
  function MutableSet() {
  }
  MutableSet.$metadata$ = {
    simpleName: 'MutableSet',
    kind: 'interface',
    interfaces: [Set, MutableCollection]
  };
  function MutableCollection() {
  }
  MutableCollection.$metadata$ = {
    simpleName: 'MutableCollection',
    kind: 'interface',
    interfaces: [Collection, MutableIterable]
  };
  function MutableIterable() {
  }
  MutableIterable.$metadata$ = {
    simpleName: 'MutableIterable',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function Enum() {
  }
  Enum.$metadata$ = {
    simpleName: 'Enum',
    kind: 'class',
    interfaces: [Comparable]
  };
  function toString_0(_this_) {
    var tmp0_safe_receiver = _this_;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toString_1(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs;
  }
  function fillArrayVal(array, initValue) {
    var inductionVariable = 0;
    var last_1 = array.length - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array[i] = initValue;
      }
       while (!(i === last_1));
    return array;
  }
  function arrayIterator(array) {
    return new _no_name_provided__41(array);
  }
  function _no_name_provided__41($array) {
    this._$array = $array;
    this._index_0 = 0;
  }
  _no_name_provided__41.prototype.hasNext_14 = function () {
    return !(this._index_0 === this._$array.length);
  };
  _no_name_provided__41.prototype.next_16 = function () {
    var tmp;
    if (!(this._index_0 === this._$array.length)) {
      var tmp0_this = this;
      var tmp1 = tmp0_this._index_0;
      tmp0_this._index_0 = tmp1 + 1 | 0;
      tmp = this._$array[tmp1];
    } else {
      throw NoSuchElementException_init_$Create$_0('' + this._index_0);
    }
    return tmp;
  };
  _no_name_provided__41.$metadata$ = {
    kind: 'class',
    interfaces: [Iterator]
  };
  var buf;
  var bufFloat64;
  var bufInt32;
  var lowIndex;
  var highIndex;
  function getNumberHashCode(obj) {
    var tmp0_unsafeCast_0 = jsBitwiseOr(obj, 0);
    if (tmp0_unsafeCast_0 === obj) {
      return numberToInt(obj);
    } else {
    }
    bufFloat64[0] = obj;
    return imul(bufInt32[highIndex], 31) + bufInt32[lowIndex] | 0;
  }
  function bufFloat64$init$() {
    var tmp0_unsafeCast_0 = new Float64Array(buf);
    return tmp0_unsafeCast_0;
  }
  function bufInt32$init$() {
    var tmp0_unsafeCast_0 = new Int32Array(buf);
    return tmp0_unsafeCast_0;
  }
  function lowIndex$init$() {
    bufFloat64[0] = -1.0;
    return !(bufInt32[0] === 0) ? 1 : 0;
  }
  function charSequenceGet(a, index) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.charCodeAt(index);
      var tmp1_Char_0 = tmp0_unsafeCast_0;
      var tmp_0;
      Companion_getInstance_5();
      var tmp0__get_code__0_1 = new Char(0);
      if (tmp1_Char_0 < tmp0__get_code__0_1.toInt_5()) {
        tmp_0 = true;
      } else {
        {
          Companion_getInstance_5();
          var tmp1__get_code__0_2 = new Char(65535);
          tmp_0 = tmp1_Char_0 > tmp1__get_code__0_2.toInt_5();
        }
      }
      if (tmp_0) {
        throw IllegalArgumentException_init_$Create$('' + 'Invalid Char code: ' + tmp1_Char_0);
      } else {
      }
      tmp = new Char(_UShort___init__impl_(toShort(tmp1_Char_0)));
    } else {
      tmp = a.get_30(index);
    }
    return tmp;
  }
  function isString(a) {
    return typeof a === 'string';
  }
  function charSequenceLength(a) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.length;
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a._get_length__31();
    }
    return tmp;
  }
  function charSequenceSubSequence(a, startIndex, endIndex) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.substring(startIndex, endIndex);
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a.subSequence_1(startIndex, endIndex);
    }
    return tmp;
  }
  function arrayToString(array) {
    return joinToString$default(array, ', ', '[', ']', 0, null, _no_name_provided_$factory_29(), 24, null);
  }
  function _no_name_provided__42() {
  }
  _no_name_provided__42.prototype.invoke_58 = function (it) {
    return toString_1(it);
  };
  _no_name_provided__42.prototype.invoke_84 = function (p1) {
    return this.invoke_58((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__42.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_29() {
    var i = new _no_name_provided__42();
    return function (p1) {
      return i.invoke_58(p1);
    };
  }
  function compareTo(a, b) {
    var tmp0_subject = typeof a;
    var tmp;
    switch (tmp0_subject) {
      case 'number':
        var tmp_0;
        if (typeof b === 'number') {
          tmp_0 = doubleCompareTo(a, b);
        } else {
          if (b instanceof Long) {
            tmp_0 = doubleCompareTo(a, b.toDouble_4());
          } else {
            {
              tmp_0 = primitiveCompareTo(a, b);
            }
          }
        }

        tmp = tmp_0;
        break;
      case 'string':
      case 'boolean':
        tmp = primitiveCompareTo(a, b);
        break;
      default:tmp = compareToDoNotIntrinsicify(a, b);
        break;
    }
    return tmp;
  }
  function doubleCompareTo(a, b) {
    var tmp;
    if (a < b) {
      tmp = -1;
    } else if (a > b) {
      tmp = 1;
    } else if (a === b) {
      var tmp_0;
      if (a !== 0) {
        tmp_0 = 0;
      } else {
        var tmp0_asDynamic_0 = 1;
        var ia = tmp0_asDynamic_0 / a;
        var tmp_1;
        var tmp1_asDynamic_0 = 1;
        if (ia === tmp1_asDynamic_0 / b) {
          tmp_1 = 0;
        } else {
          if (ia < 0) {
            tmp_1 = -1;
          } else {
            {
              tmp_1 = 1;
            }
          }
        }
        tmp_0 = tmp_1;
      }
      tmp = tmp_0;
    } else if (a !== a) {
      tmp = b !== b ? 0 : 1;
    } else {
      tmp = -1;
    }
    return tmp;
  }
  function primitiveCompareTo(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  function compareToDoNotIntrinsicify(a, b) {
    return a.compareTo_3(b);
  }
  function getObjectHashCode(obj) {
    if (!jsIn('kotlinHashCodeValue$', obj)) {
      var hash = jsBitwiseOr(Math.random() * 4.294967296E9, 0);
      var descriptor = new Object();
      descriptor.value = hash;
      descriptor.enumerable = false;
      Object.defineProperty(obj, 'kotlinHashCodeValue$', descriptor);
    }var tmp0_unsafeCast_0 = obj['kotlinHashCodeValue$'];
    return tmp0_unsafeCast_0;
  }
  function toString_1(o) {
    var tmp;
    if (o == null) {
      tmp = 'null';
    } else if (isArrayish(o)) {
      tmp = '[...]';
    } else {
      var tmp0_unsafeCast_0 = o.toString();
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function hashCode(obj) {
    if (obj == null)
      return 0;
    var tmp0_subject = typeof obj;
    var tmp;
    switch (tmp0_subject) {
      case 'object':
        tmp = 'function' === typeof obj.hashCode ? obj.hashCode() : getObjectHashCode(obj);
        break;
      case 'function':
        tmp = getObjectHashCode(obj);
        break;
      case 'number':
        tmp = getNumberHashCode(obj);
        break;
      case 'boolean':
        var tmp_0;
        if (obj) {
          tmp_0 = 1;
        } else {
          {
            tmp_0 = 0;
          }
        }

        tmp = tmp_0;
        break;
      default:tmp = getStringHashCode(hashCode$outlinedJsCode$(obj));
        break;
    }
    return tmp;
  }
  function getStringHashCode(str) {
    var hash = 0;
    var length = str.length;
    var inductionVariable = 0;
    var last_1 = length - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var code = str.charCodeAt(i);
        hash = imul(hash, 31) + code | 0;
      }
       while (!(i === last_1));
    return hash;
  }
  function equals_0(obj1, obj2) {
    if (obj1 == null) {
      return obj2 == null;
    }if (obj2 == null) {
      return false;
    }if (typeof obj1 === 'object' ? typeof obj1.equals === 'function' : false) {
      return obj1.equals(obj2);
    }if (obj1 !== obj1) {
      return obj2 !== obj2;
    }if (typeof obj1 === 'number' ? typeof obj2 === 'number' : false) {
      var tmp;
      if (obj1 === obj2) {
        var tmp_0;
        if (obj1 !== 0) {
          tmp_0 = true;
        } else {
          var tmp0_asDynamic_0 = 1;
          var tmp_1 = tmp0_asDynamic_0 / obj1;
          var tmp1_asDynamic_0 = 1;
          tmp_0 = tmp_1 === tmp1_asDynamic_0 / obj2;
        }
        tmp = tmp_0;
      } else {
        tmp = false;
      }
      return tmp;
    }return obj1 === obj2;
  }
  function captureStack(instance, constructorFunction) {
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(instance, constructorFunction);
    } else {
      instance.stack = (new Error()).stack;
    }
  }
  function extendThrowable(this_, message, cause) {
    Error.call(this_);
    setPropertiesToThrowableInstance(this_, message, cause);
  }
  function setPropertiesToThrowableInstance(this_, message, cause) {
    if (!hasOwnPrototypeProperty(this_, 'message')) {
      var tmp;
      if (message == null) {
        var tmp_0;
        if (!(message === null)) {
          var tmp0_safe_receiver = cause;
          var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.toString();
          tmp_0 = tmp1_elvis_lhs == null ? undefined : tmp1_elvis_lhs;
        } else {
          tmp_0 = undefined;
        }
        tmp = tmp_0;
      } else {
        tmp = message;
      }
      this_.message = tmp;
    }if (!hasOwnPrototypeProperty(this_, 'cause')) {
      this_.cause = cause;
    }this_.name = Object.getPrototypeOf(this_).constructor.name;
  }
  function hasOwnPrototypeProperty(o, name) {
    var tmp0_unsafeCast_0 = Object.getPrototypeOf(o).hasOwnProperty(name);
    return tmp0_unsafeCast_0;
  }
  function hashCode$outlinedJsCode$(obj) {
    return String(obj);
  }
  function ensureNotNull(v) {
    var tmp;
    if (v == null) {
      THROW_NPE();
    } else {
      tmp = v;
    }
    return tmp;
  }
  function THROW_NPE() {
    throw NullPointerException_init_$Create$();
  }
  function THROW_CCE() {
    throw ClassCastException_init_$Create$();
  }
  function Companion_7() {
    Companion_instance_6 = this;
    this._MIN_VALUE_1 = new Long(0, -2147483648);
    this._MAX_VALUE_1 = new Long(-1, 2147483647);
    this._SIZE_BYTES_1 = 8;
    this._SIZE_BITS_1 = 64;
  }
  Companion_7.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_6;
  function Companion_getInstance_6() {
    if (Companion_instance_6 == null)
      new Companion_7();
    return Companion_instance_6;
  }
  function Long(low, high) {
    Companion_getInstance_6();
    Number_0.call(this);
    this._low = low;
    this._high = high;
  }
  Long.prototype.compareTo_32 = function (other) {
    return compare(this, other);
  };
  Long.prototype.compareTo_3 = function (other) {
    return this.compareTo_32(other instanceof Long ? other : THROW_CCE());
  };
  Long.prototype.plus_27 = function (other) {
    return add(this, other);
  };
  Long.prototype.div_27 = function (other) {
    return divide(this, other);
  };
  Long.prototype.unaryMinus_4 = function () {
    return this.inv_0().plus_27(new Long(1, 0));
  };
  Long.prototype.inv_0 = function () {
    return new Long(~this._low, ~this._high);
  };
  Long.prototype.toInt_5 = function () {
    return this._low;
  };
  Long.prototype.toDouble_4 = function () {
    return toNumber(this);
  };
  Long.prototype.valueOf = function () {
    return this.toDouble_4();
  };
  Long.prototype.equals = function (other) {
    var tmp;
    if (other instanceof Long) {
      tmp = equalsLong(this, other);
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  Long.prototype.hashCode = function () {
    return hashCode_0(this);
  };
  Long.prototype.toString = function () {
    return toStringImpl(this, 10);
  };
  Long.$metadata$ = {
    simpleName: 'Long',
    kind: 'class',
    interfaces: [Comparable]
  };
  var ZERO;
  var ONE;
  var NEG_ONE;
  var MAX_VALUE;
  var MIN_VALUE;
  var TWO_PWR_24_;
  function compare(_this_, other) {
    if (equalsLong(_this_, other)) {
      return 0;
    }var thisNeg = isNegative(_this_);
    var otherNeg = isNegative(other);
    return (thisNeg ? !otherNeg : false) ? -1 : (!thisNeg ? otherNeg : false) ? 1 : isNegative(subtract(_this_, other)) ? -1 : 1;
  }
  function add(_this_, other) {
    var a48 = _this_._high >>> 16;
    var a32 = _this_._high & 65535;
    var a16 = _this_._low >>> 16;
    var a00 = _this_._low & 65535;
    var b48 = other._high >>> 16;
    var b32 = other._high & 65535;
    var b16 = other._low >>> 16;
    var b00 = other._low & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + (a00 + b00 | 0) | 0;
    c16 = c16 + (c00 >>> 16) | 0;
    c00 = c00 & 65535;
    c16 = c16 + (a16 + b16 | 0) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c32 = c32 + (a32 + b32 | 0) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (a48 + b48 | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function subtract(_this_, other) {
    return add(_this_, other.unaryMinus_4());
  }
  function multiply(_this_, other) {
    if (isZero(_this_)) {
      return ZERO;
    } else if (isZero(other)) {
      return ZERO;
    }if (equalsLong(_this_, MIN_VALUE)) {
      return isOdd(other) ? MIN_VALUE : ZERO;
    } else if (equalsLong(other, MIN_VALUE)) {
      return isOdd(_this_) ? MIN_VALUE : ZERO;
    }if (isNegative(_this_)) {
      var tmp;
      if (isNegative(other)) {
        tmp = multiply(negate(_this_), negate(other));
      } else {
        tmp = negate(multiply(negate(_this_), other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(multiply(_this_, negate(other)));
    }if (lessThan(_this_, TWO_PWR_24_) ? lessThan(other, TWO_PWR_24_) : false) {
      return fromNumber(toNumber(_this_) * toNumber(other));
    }var a48 = _this_._high >>> 16;
    var a32 = _this_._high & 65535;
    var a16 = _this_._low >>> 16;
    var a00 = _this_._low & 65535;
    var b48 = other._high >>> 16;
    var b32 = other._high & 65535;
    var b16 = other._low >>> 16;
    var b00 = other._low & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + imul(a00, b00) | 0;
    c16 = c16 + (c00 >>> 16) | 0;
    c00 = c00 & 65535;
    c16 = c16 + imul(a16, b00) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c16 = c16 + imul(a00, b16) | 0;
    c32 = c32 + (c16 >>> 16) | 0;
    c16 = c16 & 65535;
    c32 = c32 + imul(a32, b00) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a16, b16) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a00, b32) | 0;
    c48 = c48 + (c32 >>> 16) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (((imul(a48, b00) + imul(a32, b16) | 0) + imul(a16, b32) | 0) + imul(a00, b48) | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function divide(_this_, other) {
    if (isZero(other)) {
      throw Exception_init_$Create$('division by zero');
    } else if (isZero(_this_)) {
      return ZERO;
    }if (equalsLong(_this_, MIN_VALUE)) {
      if (equalsLong(other, ONE) ? true : equalsLong(other, NEG_ONE)) {
        return MIN_VALUE;
      } else if (equalsLong(other, MIN_VALUE)) {
        return ONE;
      } else {
        var halfThis = shiftRight(_this_, 1);
        var approx = shiftLeft(halfThis.div_27(other), 1);
        if (equalsLong(approx, ZERO)) {
          return isNegative(other) ? ONE : NEG_ONE;
        } else {
          var rem = subtract(_this_, multiply(other, approx));
          return add(approx, rem.div_27(other));
        }
      }
    } else if (equalsLong(other, MIN_VALUE)) {
      return ZERO;
    }if (isNegative(_this_)) {
      var tmp;
      if (isNegative(other)) {
        tmp = negate(_this_).div_27(negate(other));
      } else {
        tmp = negate(negate(_this_).div_27(other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(_this_.div_27(negate(other)));
    }var res = ZERO;
    var rem_0 = _this_;
    while (greaterThanOrEqual(rem_0, other)) {
      var approxDouble = toNumber(rem_0) / toNumber(other);
      var approx2 = function () {
        var $externalVarargReceiverTmp = Math;
        return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Float64Array([1.0, Math.floor(approxDouble)]))));
      }.call(this);
      var log2 = Math.ceil(Math.log(approx2) / Math.LN2);
      var delta = log2 <= 48.0 ? 1.0 : Math.pow(2.0, log2 - 48);
      var approxRes = fromNumber(approx2);
      var approxRem = multiply(approxRes, other);
      while (isNegative(approxRem) ? true : greaterThan(approxRem, rem_0)) {
        approx2 = approx2 - delta;
        approxRes = fromNumber(approx2);
        approxRem = multiply(approxRes, other);
      }
      if (isZero(approxRes)) {
        approxRes = ONE;
      }res = add(res, approxRes);
      rem_0 = subtract(rem_0, approxRem);
    }
    return res;
  }
  function shiftLeft(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low << numBits_0, _this_._high << numBits_0 | _this_._low >>> (32 - numBits_0 | 0));
      } else {
        return new Long(0, _this_._low << (numBits_0 - 32 | 0));
      }
    }
  }
  function shiftRight(_this_, numBits) {
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this_;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this_._low >>> numBits_0 | _this_._high << (32 - numBits_0 | 0), _this_._high >> numBits_0);
      } else {
        return new Long(_this_._high >> (numBits_0 - 32 | 0), _this_._high >= 0 ? 0 : -1);
      }
    }
  }
  function toNumber(_this_) {
    return _this_._high * 4.294967296E9 + getLowBitsUnsigned(_this_);
  }
  function equalsLong(_this_, other) {
    return _this_._high === other._high ? _this_._low === other._low : false;
  }
  function hashCode_0(l) {
    return l._low ^ l._high;
  }
  function toStringImpl(_this_, radix) {
    if (radix < 2 ? true : 36 < radix) {
      throw Exception_init_$Create$('' + 'radix out of range: ' + radix);
    }if (isZero(_this_)) {
      return '0';
    }if (isNegative(_this_)) {
      if (equalsLong(_this_, MIN_VALUE)) {
        var radixLong = fromInt(radix);
        var div = _this_.div_27(radixLong);
        var rem = subtract(multiply(div, radixLong), _this_).toInt_5();
        var tmp = toStringImpl(div, radix);
        var tmp0_unsafeCast_0 = rem.toString(radix);
        return tmp + tmp0_unsafeCast_0;
      } else {
        return '' + '-' + toStringImpl(negate(_this_), radix);
      }
    }var radixToPower = fromNumber(Math.pow(radix, 6.0));
    var rem_0 = _this_;
    var result = '';
    while (true) {
      var remDiv = rem_0.div_27(radixToPower);
      var intval = subtract(rem_0, multiply(remDiv, radixToPower)).toInt_5();
      var tmp1_unsafeCast_0 = intval.toString(radix);
      var digits = tmp1_unsafeCast_0;
      rem_0 = remDiv;
      if (isZero(rem_0)) {
        return digits + result;
      } else {
        while (digits.length < 6) {
          digits = '0' + digits;
        }
        result = digits + result;
      }
    }
  }
  function fromInt(value) {
    return new Long(value, value < 0 ? -1 : 0);
  }
  function isNegative(_this_) {
    return _this_._high < 0;
  }
  function isZero(_this_) {
    return _this_._high === 0 ? _this_._low === 0 : false;
  }
  function isOdd(_this_) {
    return (_this_._low & 1) === 1;
  }
  function negate(_this_) {
    return _this_.unaryMinus_4();
  }
  function lessThan(_this_, other) {
    return compare(_this_, other) < 0;
  }
  function fromNumber(value) {
    if (isNaN_0(value)) {
      return ZERO;
    } else if (value <= -9.223372036854776E18) {
      return MIN_VALUE;
    } else if (value + 1 >= 9.223372036854776E18) {
      return MAX_VALUE;
    } else if (value < 0.0) {
      return negate(fromNumber(-value));
    } else {
      var twoPwr32 = 4.294967296E9;
      return new Long(jsBitwiseOr(value % twoPwr32, 0), jsBitwiseOr(value / twoPwr32, 0));
    }
  }
  function greaterThan(_this_, other) {
    return compare(_this_, other) > 0;
  }
  function greaterThanOrEqual(_this_, other) {
    return compare(_this_, other) >= 0;
  }
  function getLowBitsUnsigned(_this_) {
    return _this_._low >= 0 ? _this_._low : 4.294967296E9 + _this_._low;
  }
  function imul(a_local, b_local) {
    var lhs = jsBitwiseAnd(a_local, 4.29490176E9) * jsBitwiseAnd(b_local, 65535);
    var rhs = jsBitwiseAnd(a_local, 65535) * b_local;
    return jsBitwiseOr(lhs + rhs, 0);
  }
  function numberToInt(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a.toInt_5();
    } else {
      {
        tmp = doubleToInt(a);
      }
    }
    return tmp;
  }
  function doubleToInt(a) {
    return a > 2.147483647E9 ? 2147483647 : a < -2.147483648E9 ? -2147483648 : jsBitwiseOr(a, 0);
  }
  function toShort(a) {
    var tmp0_unsafeCast_0 = toShort$outlinedJsCode$_1(a);
    return tmp0_unsafeCast_0;
  }
  function toShort$outlinedJsCode$_1(a) {
    return a << 16 >> 16;
  }
  function numberRangeToNumber(start, endInclusive) {
    return new IntRange(start, endInclusive);
  }
  function isArrayish(o) {
    var tmp;
    if (isJsArray(o)) {
      tmp = true;
    } else {
      var tmp0_unsafeCast_0 = ArrayBuffer.isView(o);
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function isJsArray(obj) {
    var tmp0_unsafeCast_0 = Array.isArray(obj);
    return tmp0_unsafeCast_0;
  }
  function isInterface(obj, iface) {
    var tmp0_elvis_lhs = obj.constructor;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var ctor = tmp;
    return isInterfaceImpl(ctor, iface);
  }
  function isInterfaceImpl(ctor, iface) {
    if (ctor === iface)
      return true;
    var metadata = ctor.$metadata$;
    if (!(metadata == null)) {
      var interfaces = metadata.interfaces;
      var indexedObject = interfaces;
      var inductionVariable = 0;
      var last_1 = indexedObject.length;
      while (inductionVariable < last_1) {
        var i = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (isInterfaceImpl(i, iface)) {
          return true;
        }}
    }var superPrototype = !(ctor.prototype == null) ? Object.getPrototypeOf(ctor.prototype) : null;
    var superConstructor = superPrototype != null ? superPrototype.constructor : null;
    return !(superConstructor == null) ? isInterfaceImpl(superConstructor, iface) : false;
  }
  function isArray(obj) {
    var tmp;
    if (isJsArray(obj)) {
      tmp = !obj.$type$;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function isObject(obj) {
    var objTypeOf = typeof obj;
    var tmp0_subject = objTypeOf;
    switch (tmp0_subject) {
      case 'string':
        return true;
      case 'number':
        return true;
      case 'boolean':
        return true;
      case 'function':
        return true;
      default:return jsInstanceOf(obj, Object);
    }
  }
  function isNumber(a) {
    var tmp;
    if (typeof a === 'number') {
      tmp = true;
    } else {
      tmp = a instanceof Long;
    }
    return tmp;
  }
  function isCharSequence(value) {
    return typeof value === 'string' ? true : isInterface(value, _get_js_(getKClass_0(CharSequence)));
  }
  function isBooleanArray(a) {
    return isJsArray(a) ? a.$type$ === 'BooleanArray' : false;
  }
  function isByteArray(a) {
    return jsInstanceOf(a, Int8Array);
  }
  function isShortArray(a) {
    return jsInstanceOf(a, Int16Array);
  }
  function isCharArray(a) {
    return isJsArray(a) ? a.$type$ === 'CharArray' : false;
  }
  function isIntArray(a) {
    return jsInstanceOf(a, Int32Array);
  }
  function isFloatArray(a) {
    return jsInstanceOf(a, Float32Array);
  }
  function isLongArray(a) {
    return isJsArray(a) ? a.$type$ === 'LongArray' : false;
  }
  function isDoubleArray(a) {
    return jsInstanceOf(a, Float64Array);
  }
  function asList(_this_) {
    return new ArrayList(_this_);
  }
  function isWhitespaceImpl(_this_) {
    var ch = _this_.toInt_5();
    return (((9 <= ch ? ch <= 13 : false) ? true : 28 <= ch ? ch <= 32 : false) ? true : ch === 160) ? true : ch > 4096 ? (((((ch === 5760 ? true : 8192 <= ch ? ch <= 8202 : false) ? true : ch === 8232) ? true : ch === 8233) ? true : ch === 8239) ? true : ch === 8287) ? true : ch === 12288 : false;
  }
  function Exception_init_$Init$($this) {
    extendThrowable($this, void 1, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Init$_0(message, $this) {
    extendThrowable($this, message, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$(message) {
    var tmp = Exception_init_$Init$_0(message, Object.create(Exception.prototype));
    captureStack(tmp, Exception_init_$Create$);
    return tmp;
  }
  function Exception() {
    captureStack(this, Exception);
  }
  Exception.$metadata$ = {
    simpleName: 'Exception',
    kind: 'class',
    interfaces: []
  };
  function IllegalArgumentException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$(message) {
    var tmp = IllegalArgumentException_init_$Init$(message, Object.create(IllegalArgumentException.prototype));
    captureStack(tmp, IllegalArgumentException_init_$Create$);
    return tmp;
  }
  function IllegalArgumentException() {
    captureStack(this, IllegalArgumentException);
  }
  IllegalArgumentException.$metadata$ = {
    simpleName: 'IllegalArgumentException',
    kind: 'class',
    interfaces: []
  };
  function RuntimeException_init_$Init$($this) {
    Exception_init_$Init$($this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Init$_0(message, $this) {
    Exception_init_$Init$_0(message, $this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException() {
    captureStack(this, RuntimeException);
  }
  RuntimeException.$metadata$ = {
    simpleName: 'RuntimeException',
    kind: 'class',
    interfaces: []
  };
  function NoSuchElementException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$() {
    var tmp = NoSuchElementException_init_$Init$(Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$);
    return tmp;
  }
  function NoSuchElementException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$_0(message) {
    var tmp = NoSuchElementException_init_$Init$_0(message, Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$_0);
    return tmp;
  }
  function NoSuchElementException() {
    captureStack(this, NoSuchElementException);
  }
  NoSuchElementException.$metadata$ = {
    simpleName: 'NoSuchElementException',
    kind: 'class',
    interfaces: []
  };
  function IllegalStateException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$(message) {
    var tmp = IllegalStateException_init_$Init$(message, Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$);
    return tmp;
  }
  function IllegalStateException() {
    captureStack(this, IllegalStateException);
  }
  IllegalStateException.$metadata$ = {
    simpleName: 'IllegalStateException',
    kind: 'class',
    interfaces: []
  };
  function IndexOutOfBoundsException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IndexOutOfBoundsException.call($this);
    return $this;
  }
  function IndexOutOfBoundsException_init_$Create$(message) {
    var tmp = IndexOutOfBoundsException_init_$Init$(message, Object.create(IndexOutOfBoundsException.prototype));
    captureStack(tmp, IndexOutOfBoundsException_init_$Create$);
    return tmp;
  }
  function IndexOutOfBoundsException() {
    captureStack(this, IndexOutOfBoundsException);
  }
  IndexOutOfBoundsException.$metadata$ = {
    simpleName: 'IndexOutOfBoundsException',
    kind: 'class',
    interfaces: []
  };
  function UnsupportedOperationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$() {
    var tmp = UnsupportedOperationException_init_$Init$(Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$);
    return tmp;
  }
  function UnsupportedOperationException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$_0(message) {
    var tmp = UnsupportedOperationException_init_$Init$_0(message, Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$_0);
    return tmp;
  }
  function UnsupportedOperationException() {
    captureStack(this, UnsupportedOperationException);
  }
  UnsupportedOperationException.$metadata$ = {
    simpleName: 'UnsupportedOperationException',
    kind: 'class',
    interfaces: []
  };
  function ArithmeticException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    ArithmeticException.call($this);
    return $this;
  }
  function ArithmeticException_init_$Create$(message) {
    var tmp = ArithmeticException_init_$Init$(message, Object.create(ArithmeticException.prototype));
    captureStack(tmp, ArithmeticException_init_$Create$);
    return tmp;
  }
  function ArithmeticException() {
    captureStack(this, ArithmeticException);
  }
  ArithmeticException.$metadata$ = {
    simpleName: 'ArithmeticException',
    kind: 'class',
    interfaces: []
  };
  function NullPointerException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NullPointerException.call($this);
    return $this;
  }
  function NullPointerException_init_$Create$() {
    var tmp = NullPointerException_init_$Init$(Object.create(NullPointerException.prototype));
    captureStack(tmp, NullPointerException_init_$Create$);
    return tmp;
  }
  function NullPointerException() {
    captureStack(this, NullPointerException);
  }
  NullPointerException.$metadata$ = {
    simpleName: 'NullPointerException',
    kind: 'class',
    interfaces: []
  };
  function ClassCastException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ClassCastException.call($this);
    return $this;
  }
  function ClassCastException_init_$Create$() {
    var tmp = ClassCastException_init_$Init$(Object.create(ClassCastException.prototype));
    captureStack(tmp, ClassCastException_init_$Create$);
    return tmp;
  }
  function ClassCastException() {
    captureStack(this, ClassCastException);
  }
  ClassCastException.$metadata$ = {
    simpleName: 'ClassCastException',
    kind: 'class',
    interfaces: []
  };
  function jsIn(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsIn$outlinedJsCode$(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseOr(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsBitwiseOr$outlinedJsCode$_0(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsDeleteProperty(obj_hack, property_hack) {
    jsDeleteProperty$outlinedJsCode$_1(obj_hack, property_hack);
  }
  function jsTypeOf(value_hack) {
    var tmp0_unsafeCast_0 = jsTypeOf$outlinedJsCode$_2(value_hack);
    return tmp0_unsafeCast_0;
  }
  function jsInstanceOf(obj_hack, jsClass_hack) {
    var tmp0_unsafeCast_0 = jsInstanceOf$outlinedJsCode$_3(obj_hack, jsClass_hack);
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseAnd(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsBitwiseAnd$outlinedJsCode$_4(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsIn$outlinedJsCode$(lhs_hack, rhs_hack) {
    return lhs_hack in rhs_hack;
  }
  function jsBitwiseOr$outlinedJsCode$_0(lhs_hack, rhs_hack) {
    return lhs_hack | rhs_hack;
  }
  function jsDeleteProperty$outlinedJsCode$_1(obj_hack, property_hack) {
    return delete obj_hack[property_hack];
  }
  function jsTypeOf$outlinedJsCode$_2(value_hack) {
    return typeof value_hack;
  }
  function jsInstanceOf$outlinedJsCode$_3(obj_hack, jsClass_hack) {
    return obj_hack instanceof jsClass_hack;
  }
  function jsBitwiseAnd$outlinedJsCode$_4(lhs_hack, rhs_hack) {
    return lhs_hack & rhs_hack;
  }
  var emptyMap_0;
  function Tag() {
  }
  Tag.prototype.unaryPlus_2 = function (_this__0) {
    this.text_3(_this__0);
  };
  Tag.prototype.text_3 = function (s) {
    this._get_consumer__3().onTagContent_1(s);
  };
  Tag.$metadata$ = {
    simpleName: 'Tag',
    kind: 'interface',
    interfaces: []
  };
  function TagConsumer() {
  }
  TagConsumer.prototype.onTagError_1 = function (tag, exception) {
    throw exception;
  };
  TagConsumer.$metadata$ = {
    simpleName: 'TagConsumer',
    kind: 'interface',
    interfaces: []
  };
  function Unsafe() {
  }
  Unsafe.$metadata$ = {
    simpleName: 'Unsafe',
    kind: 'interface',
    interfaces: []
  };
  function unsafe(_this_, block) {
    return _this_._get_consumer__3().onTagContentUnsafe_1(block);
  }
  function attributesMapOf(key, value) {
    var tmp0_subject = value;
    return tmp0_subject == null ? emptyMap_0 : singletonMapOf(key, value);
  }
  function singletonMapOf(key, value) {
    return new SingletonStringMap(key, value);
  }
  function SingletonStringMap(key, value) {
    this._key_0 = key;
    this._value_2 = value;
  }
  SingletonStringMap.prototype._get_key__7 = function () {
    return this._key_0;
  };
  SingletonStringMap.prototype._get_value__8 = function () {
    return this._value_2;
  };
  SingletonStringMap.prototype._get_entries__7 = function () {
    return setOf(this);
  };
  SingletonStringMap.prototype._get_keys__7 = function () {
    return setOf(this._key_0);
  };
  SingletonStringMap.prototype._get_size__32 = function () {
    return 1;
  };
  SingletonStringMap.prototype.containsKey_8 = function (key) {
    return key === this._key_0;
  };
  SingletonStringMap.prototype.containsKey_9 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    else {
    }
    return this.containsKey_8((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  SingletonStringMap.prototype.get_19 = function (key) {
    return key === this._key_0 ? this._value_2 : null;
  };
  SingletonStringMap.prototype.get_20 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    else {
    }
    return this.get_19((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  SingletonStringMap.prototype.isEmpty_28 = function () {
    return false;
  };
  SingletonStringMap.prototype.toString = function () {
    return '' + 'SingletonStringMap(key=' + this._key_0 + ', value=' + this._value_2 + ')';
  };
  SingletonStringMap.prototype.hashCode = function () {
    var result = getStringHashCode(this._key_0);
    result = imul(result, 31) + getStringHashCode(this._value_2) | 0;
    return result;
  };
  SingletonStringMap.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SingletonStringMap))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof SingletonStringMap ? other : THROW_CCE();
    if (!(this._key_0 === tmp0_other_with_cast._key_0))
      return false;
    if (!(this._value_2 === tmp0_other_with_cast._value_2))
      return false;
    return true;
  };
  SingletonStringMap.$metadata$ = {
    simpleName: 'SingletonStringMap',
    kind: 'class',
    interfaces: [Map_0, Entry]
  };
  function DefaultUnsafe() {
    this._sb = StringBuilder_init_$Create$_0();
  }
  DefaultUnsafe.prototype.unaryPlus_2 = function (_this__0) {
    this._sb.append_4(_this__0);
    Unit_getInstance();
  };
  DefaultUnsafe.prototype.toString = function () {
    return this._sb.toString();
  };
  DefaultUnsafe.$metadata$ = {
    simpleName: 'DefaultUnsafe',
    kind: 'class',
    interfaces: [Unsafe]
  };
  function Attribute(encoder) {
    this._encoder = encoder;
  }
  Attribute.prototype.set_0 = function (thisRef, attributeName, value) {
    thisRef._get_attributes__12().put_7(attributeName, this._encoder.encode_1(attributeName, value));
    Unit_getInstance();
  };
  Attribute.$metadata$ = {
    simpleName: 'Attribute',
    kind: 'class',
    interfaces: []
  };
  function StringAttribute() {
    Attribute.call(this, StringEncoder_getInstance());
  }
  StringAttribute.$metadata$ = {
    simpleName: 'StringAttribute',
    kind: 'class',
    interfaces: []
  };
  function AttributeEncoder() {
  }
  AttributeEncoder.$metadata$ = {
    simpleName: 'AttributeEncoder',
    kind: 'interface',
    interfaces: []
  };
  function StringEncoder() {
    StringEncoder_instance = this;
  }
  StringEncoder.prototype.encode_0 = function (attributeName, value) {
    return value;
  };
  StringEncoder.prototype.encode_1 = function (attributeName, value) {
    return this.encode_0(attributeName, (!(value == null) ? typeof value === 'string' : false) ? value : THROW_CCE());
  };
  StringEncoder.$metadata$ = {
    simpleName: 'StringEncoder',
    kind: 'object',
    interfaces: [AttributeEncoder]
  };
  var StringEncoder_instance;
  function StringEncoder_getInstance() {
    if (StringEncoder_instance == null)
      new StringEncoder();
    return StringEncoder_instance;
  }
  function switchToMutable($this) {
    var tmp;
    if ($this._backingMutable) {
      tmp = $this._backing;
    } else {
      $this._backingMutable = true;
      $this._backing = LinkedHashMap_init_$Create$_2($this._backing);
      tmp = $this._backing;
    }
    var tmp_0 = tmp;
    return isInterface(tmp_0, MutableMap) ? tmp_0 : THROW_CCE();
  }
  function DelegatingMap(initialValues, tag, consumer) {
    this._tag = tag;
    this._consumer = consumer;
    this._backing = initialValues;
    this._backingMutable = false;
  }
  DelegatingMap.prototype._get_size__32 = function () {
    return this._backing._get_size__32();
  };
  DelegatingMap.prototype.isEmpty_28 = function () {
    return this._backing.isEmpty_28();
  };
  DelegatingMap.prototype.containsKey_8 = function (key) {
    return this._backing.containsKey_9(key);
  };
  DelegatingMap.prototype.containsKey_9 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    else {
    }
    return this.containsKey_8((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  DelegatingMap.prototype.get_19 = function (key) {
    return this._backing.get_20(key);
  };
  DelegatingMap.prototype.get_20 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    else {
    }
    return this.get_19((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  DelegatingMap.prototype.put_6 = function (key, value) {
    var mutable = switchToMutable(this);
    var old = mutable.put_7(key, value);
    if (!(old === value)) {
      this._consumer().onTagAttributeChange_1(this._tag, key, value);
    }return old;
  };
  DelegatingMap.prototype.put_7 = function (key, value) {
    var tmp = (!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE();
    return this.put_6(tmp, (!(value == null) ? typeof value === 'string' : false) ? value : THROW_CCE());
  };
  DelegatingMap.prototype._get_immutableEntries_ = function () {
    return this._backing._get_entries__7();
  };
  DelegatingMap.prototype._get_keys__7 = function () {
    return switchToMutable(this)._get_keys__7();
  };
  DelegatingMap.prototype._get_entries__7 = function () {
    return switchToMutable(this)._get_entries__7();
  };
  DelegatingMap.$metadata$ = {
    simpleName: 'DelegatingMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function onFinalize(_this_, block) {
    return new FinalizeConsumer(_this_, _no_name_provided_$factory_30(block));
  }
  function FinalizeConsumer(downstream, block) {
    this._downstream = downstream;
    this._block = block;
    this._level = 0;
  }
  FinalizeConsumer.prototype.onTagStart_1 = function (tag) {
    this._downstream.onTagStart_1(tag);
    var tmp0_this = this;
    var tmp1 = tmp0_this._level;
    tmp0_this._level = tmp1 + 1 | 0;
    Unit_getInstance();
  };
  FinalizeConsumer.prototype.onTagEnd_1 = function (tag) {
    this._downstream.onTagEnd_1(tag);
    var tmp0_this = this;
    var tmp1 = tmp0_this._level;
    tmp0_this._level = tmp1 - 1 | 0;
    Unit_getInstance();
  };
  FinalizeConsumer.prototype.onTagAttributeChange_1 = function (tag, attribute, value) {
    return this._downstream.onTagAttributeChange_1(tag, attribute, value);
  };
  FinalizeConsumer.prototype.onTagContent_1 = function (content) {
    return this._downstream.onTagContent_1(content);
  };
  FinalizeConsumer.prototype.onTagContentUnsafe_1 = function (block) {
    return this._downstream.onTagContentUnsafe_1(block);
  };
  FinalizeConsumer.prototype.onTagError_1 = function (tag, exception) {
    return this._downstream.onTagError_1(tag, exception);
  };
  FinalizeConsumer.prototype.finalize_1 = function () {
    return this._block(this._downstream.finalize_1(), this._level > 0);
  };
  FinalizeConsumer.$metadata$ = {
    simpleName: 'FinalizeConsumer',
    kind: 'class',
    interfaces: [TagConsumer]
  };
  function _no_name_provided__43($block) {
    this._$block = $block;
  }
  _no_name_provided__43.prototype.invoke_60 = function (to_0, partial) {
    this._$block(to_0, partial);
    return to_0;
  };
  _no_name_provided__43.prototype.invoke_64 = function (p1, p2) {
    var tmp = (p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE();
    return this.invoke_60(tmp, (!(p2 == null) ? typeof p2 === 'boolean' : false) ? p2 : THROW_CCE());
  };
  _no_name_provided__43.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_30($block) {
    var i = new _no_name_provided__43($block);
    return function (p1, p2) {
      return i.invoke_60(p1, p2);
    };
  }
  function _set_id_(_this_, newValue) {
    attributeStringString.set_0(_this_, 'id', newValue);
  }
  function CommonAttributeGroupFacade() {
  }
  CommonAttributeGroupFacade.$metadata$ = {
    simpleName: 'CommonAttributeGroupFacade',
    kind: 'interface',
    interfaces: [Tag]
  };
  var attributeStringString;
  function HtmlBlockTag() {
  }
  HtmlBlockTag.$metadata$ = {
    simpleName: 'HtmlBlockTag',
    kind: 'interface',
    interfaces: [CommonAttributeGroupFacade, FlowContent]
  };
  function HtmlBlockInlineTag() {
  }
  HtmlBlockInlineTag.$metadata$ = {
    simpleName: 'HtmlBlockInlineTag',
    kind: 'interface',
    interfaces: [CommonAttributeGroupFacade, FlowPhrasingContent, HtmlBlockTag, HtmlInlineTag]
  };
  function FlowPhrasingContent() {
  }
  FlowPhrasingContent.$metadata$ = {
    simpleName: 'FlowPhrasingContent',
    kind: 'interface',
    interfaces: [FlowContent, PhrasingContent]
  };
  function HtmlInlineTag() {
  }
  HtmlInlineTag.$metadata$ = {
    simpleName: 'HtmlInlineTag',
    kind: 'interface',
    interfaces: [CommonAttributeGroupFacade, PhrasingContent]
  };
  function FlowContent() {
  }
  FlowContent.$metadata$ = {
    simpleName: 'FlowContent',
    kind: 'interface',
    interfaces: [FlowOrMetaDataOrPhrasingContent, FlowOrHeadingContent, FlowOrMetaDataContent, FlowOrInteractiveContent, FlowOrPhrasingContent, SectioningOrFlowContent, FlowOrInteractiveOrPhrasingContent, Tag]
  };
  function PhrasingContent() {
  }
  PhrasingContent.$metadata$ = {
    simpleName: 'PhrasingContent',
    kind: 'interface',
    interfaces: [FlowOrMetaDataOrPhrasingContent, FlowOrPhrasingContent, FlowOrInteractiveOrPhrasingContent, Tag]
  };
  function FlowOrMetaDataOrPhrasingContent() {
  }
  FlowOrMetaDataOrPhrasingContent.$metadata$ = {
    simpleName: 'FlowOrMetaDataOrPhrasingContent',
    kind: 'interface',
    interfaces: [Tag]
  };
  function FlowOrHeadingContent() {
  }
  FlowOrHeadingContent.$metadata$ = {
    simpleName: 'FlowOrHeadingContent',
    kind: 'interface',
    interfaces: [Tag]
  };
  function FlowOrMetaDataContent() {
  }
  FlowOrMetaDataContent.$metadata$ = {
    simpleName: 'FlowOrMetaDataContent',
    kind: 'interface',
    interfaces: [FlowOrMetaDataOrPhrasingContent, Tag]
  };
  function FlowOrInteractiveContent() {
  }
  FlowOrInteractiveContent.$metadata$ = {
    simpleName: 'FlowOrInteractiveContent',
    kind: 'interface',
    interfaces: [FlowOrInteractiveOrPhrasingContent, Tag]
  };
  function FlowOrPhrasingContent() {
  }
  FlowOrPhrasingContent.$metadata$ = {
    simpleName: 'FlowOrPhrasingContent',
    kind: 'interface',
    interfaces: [FlowOrInteractiveOrPhrasingContent, FlowOrMetaDataOrPhrasingContent, Tag]
  };
  function SectioningOrFlowContent() {
  }
  SectioningOrFlowContent.$metadata$ = {
    simpleName: 'SectioningOrFlowContent',
    kind: 'interface',
    interfaces: [Tag]
  };
  function FlowOrInteractiveOrPhrasingContent() {
  }
  FlowOrInteractiveOrPhrasingContent.$metadata$ = {
    simpleName: 'FlowOrInteractiveOrPhrasingContent',
    kind: 'interface',
    interfaces: [Tag]
  };
  function DIV(initialAttributes, consumer) {
    HTMLTag.call(this, 'div', consumer, initialAttributes, null, false, false);
    this._consumer_0 = consumer;
  }
  DIV.prototype._get_consumer__3 = function () {
    return this._consumer_0;
  };
  DIV.$metadata$ = {
    simpleName: 'DIV',
    kind: 'class',
    interfaces: [HtmlBlockTag]
  };
  function PRE(initialAttributes, consumer) {
    HTMLTag.call(this, 'pre', consumer, initialAttributes, null, false, false);
    this._consumer_1 = consumer;
  }
  PRE.prototype._get_consumer__3 = function () {
    return this._consumer_1;
  };
  PRE.$metadata$ = {
    simpleName: 'PRE',
    kind: 'class',
    interfaces: [HtmlBlockInlineTag]
  };
  function _no_name_provided__44(this$0) {
    this._this$0_10 = this$0;
  }
  _no_name_provided__44.prototype.invoke_62 = function () {
    return this._this$0_10._get_consumer__3();
  };
  _no_name_provided__44.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function HTMLTag(tagName, consumer, initialAttributes, namespace, inlineTag, emptyTag) {
    this._tagName = tagName;
    this._consumer_2 = consumer;
    this._namespace = namespace;
    this._inlineTag = inlineTag;
    this._emptyTag = emptyTag;
    var tmp = this;
    tmp._attributes = new DelegatingMap(initialAttributes, this, _no_name_provided_$factory_31(this));
  }
  HTMLTag.prototype._get_tagName__12 = function () {
    return this._tagName;
  };
  HTMLTag.prototype._get_consumer__3 = function () {
    return this._consumer_2;
  };
  HTMLTag.prototype._get_namespace__2 = function () {
    return this._namespace;
  };
  HTMLTag.prototype._get_attributes__12 = function () {
    return this._attributes;
  };
  HTMLTag.prototype._get_attributesEntries__2 = function () {
    return this._get_attributes__12()._get_immutableEntries_();
  };
  HTMLTag.$metadata$ = {
    simpleName: 'HTMLTag',
    kind: 'class',
    interfaces: [Tag]
  };
  function _no_name_provided_$factory_31(this$0) {
    var i = new _no_name_provided__44(this$0);
    return function () {
      return i.invoke_62();
    };
  }
  function _get_create_(_this_) {
    return new JSDOMBuilder(_this_);
  }
  function _get_append_(_this_) {
    var tmp = createTree(_get_ownerDocumentExt_(_this_));
    return onFinalize(tmp, _no_name_provided_$factory_32(_this_));
  }
  function asR(_this_, $this) {
    return _this_;
  }
  function JSDOMBuilder(document_0) {
    this._document = document_0;
    var tmp = this;
    tmp._path = ArrayList_init_$Create$();
    this._lastLeaved = null;
  }
  JSDOMBuilder.prototype.onTagStart_1 = function (tag) {
    var tmp;
    if (!(tag._get_namespace__2() == null)) {
      var tmp0_asDynamic_0 = this._document.createElementNS(ensureNotNull(tag._get_namespace__2()), tag._get_tagName__12());
      tmp = tmp0_asDynamic_0;
    } else {
      var tmp_0 = this._document.createElement(tag._get_tagName__12());
      tmp = tmp_0 instanceof HTMLElement ? tmp_0 : THROW_CCE();
    }
    var element = tmp;
    var tmp1_forEach_0 = tag._get_attributesEntries__2();
    var tmp0_iterator_1 = tmp1_forEach_0.iterator_40();
    while (tmp0_iterator_1.hasNext_14()) {
      var element_2 = tmp0_iterator_1.next_16();
      element.setAttribute(element_2._get_key__7(), element_2._get_value__8());
    }
    var tmp2_isNotEmpty_0 = this._path;
    if (!tmp2_isNotEmpty_0.isEmpty_28()) {
      last_0(this._path).appendChild(element);
      Unit_getInstance();
    } else {
    }
    this._path.add_16(element);
    Unit_getInstance();
  };
  JSDOMBuilder.prototype.onTagAttributeChange_1 = function (tag, attribute, value) {
    if (this._path.isEmpty_28())
      throw IllegalStateException_init_$Create$('No current tag');
    else {
      var tmp0_toLowerCase_0 = last_0(this._path).tagName;
      var tmp = tmp0_toLowerCase_0.toLowerCase();
      var tmp1_toLowerCase_0 = tag._get_tagName__12();
      if (!(tmp === tmp1_toLowerCase_0.toLowerCase()))
        throw IllegalStateException_init_$Create$('Wrong current tag');
      else {
        {
          var tmp2_let_0 = last_0(this._path);
          if (value == null) {
            tmp2_let_0.removeAttribute(attribute);
          } else {
            tmp2_let_0.setAttribute(attribute, value);
          }
        }
      }
    }
  };
  JSDOMBuilder.prototype.onTagEnd_1 = function (tag) {
    var tmp;
    if (this._path.isEmpty_28()) {
      tmp = true;
    } else {
      var tmp0_toLowerCase_0 = last_0(this._path).tagName;
      var tmp_0 = tmp0_toLowerCase_0.toLowerCase();
      var tmp1_toLowerCase_0 = tag._get_tagName__12();
      tmp = !(tmp_0 === tmp1_toLowerCase_0.toLowerCase());
    }
    if (tmp) {
      throw IllegalStateException_init_$Create$('' + "We haven't entered tag " + tag._get_tagName__12() + ' but trying to leave');
    } else {
    }
    this._lastLeaved = this._path.removeAt(_get_lastIndex_(this._path));
  };
  JSDOMBuilder.prototype.onTagContent_1 = function (content) {
    if (this._path.isEmpty_28()) {
      throw IllegalStateException_init_$Create$('No current DOM node');
    }last_0(this._path).appendChild(this._document.createTextNode(toString_1(content)));
    Unit_getInstance();
  };
  JSDOMBuilder.prototype.onTagContentUnsafe_1 = function (block) {
    var tmp0_with_0 = new DefaultUnsafe();
    block(tmp0_with_0);
    var tmp0_this_2 = last_0(this._path);
    tmp0_this_2.innerHTML = tmp0_this_2.innerHTML + tmp0_with_0.toString();
  };
  JSDOMBuilder.prototype.finalize_1 = function () {
    var tmp0_safe_receiver = this._lastLeaved;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : asR(tmp0_safe_receiver, this);
    var tmp;
    if (tmp1_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$("We can't finalize as there was no tags");
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  };
  JSDOMBuilder.$metadata$ = {
    simpleName: 'JSDOMBuilder',
    kind: 'class',
    interfaces: [TagConsumer]
  };
  function createTree(_this_) {
    return new JSDOMBuilder(_this_);
  }
  function _get_ownerDocumentExt_(_this_) {
    var tmp;
    if (_this_ instanceof Document) {
      tmp = _this_;
    } else {
      {
        var tmp0_elvis_lhs = _this_.ownerDocument;
        var tmp_0;
        if (tmp0_elvis_lhs == null) {
          throw IllegalStateException_init_$Create$('Node has no ownerDocument');
        } else {
          tmp_0 = tmp0_elvis_lhs;
        }
        tmp = tmp_0;
      }
    }
    return tmp;
  }
  function _no_name_provided__45($this_append) {
    this._$this_append = $this_append;
  }
  _no_name_provided__45.prototype.invoke_63 = function (element, partial) {
    if (!partial) {
      this._$this_append.appendChild(element);
      Unit_getInstance();
    }};
  _no_name_provided__45.prototype.invoke_64 = function (p1, p2) {
    var tmp = p1 instanceof HTMLElement ? p1 : THROW_CCE();
    this.invoke_63(tmp, (!(p2 == null) ? typeof p2 === 'boolean' : false) ? p2 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__45.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_32($this_append) {
    var i = new _no_name_provided__45($this_append);
    return function (p1, p2) {
      i.invoke_63(p1, p2);
      return Unit_getInstance();
    };
  }
  function visitTagAndFinalize(_this_, consumer, block) {
    if (!(_this_._get_consumer__3() === consumer)) {
      throw IllegalArgumentException_init_$Create$('Wrong exception');
    }visitTag(_this_, block);
    return consumer.finalize_1();
  }
  function visitTag(_this_, block) {
    _this_._get_consumer__3().onTagStart_1(_this_);
    try {
      block(_this_);
    } catch ($p) {
      if ($p instanceof Error) {
        _this_._get_consumer__3().onTagError_1(_this_, $p);
      } else {
        {
          throw $p;
        }
      }
    }
    finally {
      _this_._get_consumer__3().onTagEnd_1(_this_);
    }
  }
  var commands;
  function _no_name_provided__46() {
    this._help = 'usage: ls (no additional flags supported)';
  }
  _no_name_provided__46.prototype._get_help__3 = function () {
    return this._help;
  };
  _no_name_provided__46.prototype.complete_3 = function (argv) {
    return emptyList();
  };
  _no_name_provided__46.prototype.exec_4 = function (argv, print) {
    if (argv.isEmpty_28()) {
      var tmp0_forEachIndexed_0 = files;
      var index_1 = 0;
      var tmp0_iterator_2 = tmp0_forEachIndexed_0.iterator_40();
      while (tmp0_iterator_2.hasNext_14()) {
        var item_3 = tmp0_iterator_2.next_16();
        var tmp1_4 = index_1;
        index_1 = tmp1_4 + 1 | 0;
        var tmp1__anonymous__5 = checkIndexOverflow(tmp1_4);
        if (argv.isEmpty_28() ? true : argv.get_30(0) === item_3._name) {
          if (tmp1__anonymous__5 > 0) {
            print(' ');
          }print(item_3._name);
        }}
    } else {
      print(this._help);
    }
  };
  _no_name_provided__46.$metadata$ = {
    kind: 'class',
    interfaces: [Command]
  };
  function _no_name_provided__47() {
    this._help_0 = 'usage: cat [file ...]';
  }
  _no_name_provided__47.prototype._get_help__3 = function () {
    return this._help_0;
  };
  _no_name_provided__47.prototype.complete_3 = function (argv) {
    var tmp0_filter_0 = files;
    var tmp0_filterTo_0_1 = ArrayList_init_$Create$();
    var tmp0_iterator_1_2 = tmp0_filter_0.iterator_40();
    while (tmp0_iterator_1_2.hasNext_14()) {
      var element_2_3 = tmp0_iterator_1_2.next_16();
      var tmp;
      var tmp_0 = argv.get_30(0);
      if (indexOf$default(element_2_3._name, tmp_0, 0, false, 6, null) === 0) {
        tmp = true;
      } else {
        {
          tmp = indexOf$default(element_2_3._name, ' ', 0, false, 6, null) === 0;
        }
      }
      if (tmp) {
        tmp0_filterTo_0_1.add_16(element_2_3);
        Unit_getInstance();
      } else {
      }
    }
    var tmp1_map_0 = tmp0_filterTo_0_1;
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map_0, 10));
    var tmp0_iterator_1_2_0 = tmp1_map_0.iterator_40();
    while (tmp0_iterator_1_2_0.hasNext_14()) {
      var item_2_3 = tmp0_iterator_1_2_0.next_16();
      tmp0_mapTo_0_1.add_16(item_2_3._name);
      Unit_getInstance();
    }
    return tmp0_mapTo_0_1;
  };
  _no_name_provided__47.prototype.exec_4 = function (argv, print) {
    if (!argv.isEmpty_28()) {
      var tmp0_forEach_0 = files;
      var tmp0_iterator_1 = tmp0_forEach_0.iterator_40();
      while (tmp0_iterator_1.hasNext_14()) {
        var element_2 = tmp0_iterator_1.next_16();
        if (element_2._name === argv.get_30(0)) {
          print('' + '<p>' + element_2._content + '<\/p>');
          return Unit_getInstance();
        }}
      print('' + argv.get_30(0) + ': no such file');
    } else {
      {
        print(this._help_0);
      }
    }
  };
  _no_name_provided__47.$metadata$ = {
    kind: 'class',
    interfaces: [Command]
  };
  function _no_name_provided__48() {
    this._help_1 = 'clear the terminal screen';
  }
  _no_name_provided__48.prototype._get_help__3 = function () {
    return this._help_1;
  };
  _no_name_provided__48.prototype.complete_3 = function (argv) {
    return emptyList();
  };
  _no_name_provided__48.prototype.exec_4 = function (argv, print) {
    return Console_getInstance().clear_0();
  };
  _no_name_provided__48.$metadata$ = {
    kind: 'class',
    interfaces: [Command]
  };
  function commands$init$() {
    var tmp = to('help', Help_getInstance());
    var tmp_0 = to('ls', new _no_name_provided__46());
    var tmp_1 = to('cat', new _no_name_provided__47());
    return mapOf([tmp, tmp_0, tmp_1, to('clear', new _no_name_provided__48())]);
  }
  function Command() {
  }
  Command.prototype.exec_4 = function (argv, print) {
  };
  Command.$metadata$ = {
    simpleName: 'Command',
    kind: 'interface',
    interfaces: []
  };
  function removeCurrentLineCursor($this) {
    return function () {
      var $externalVarargReceiverTmp = ensureNotNull($this._currentLine).classList;
      return $externalVarargReceiverTmp.remove.apply($externalVarargReceiverTmp, [].concat(['active']));
    }.call(this);
  }
  function scrollDown($this) {
    return window.scroll(0.0, ensureNotNull(document.body).scrollHeight);
  }
  function getLine($this) {
    return ensureNotNull(ensureNotNull($this._currentBody).textContent);
  }
  function setLine($this, line) {
    ensureNotNull($this._currentBody).textContent = line;
  }
  function parse($this, input) {
    var tmp1_split_0 = toString_1(trim(isCharSequence(input) ? input : THROW_CCE()));
    var tmp0_toRegex_0 = '\\s+';
    var tmp2_split_0 = Regex_init_$Create$(tmp0_toRegex_0);
    var tmp3_split_0 = 0;
    var parts = tmp2_split_0.split(tmp1_split_0, tmp3_split_0);
    return new Pair(first(parts), drop(parts, 1));
  }
  function newPrompt($this, content) {
    var tmp = $this;
    var tmp_0 = ensureNotNull($this._consoleLineTemplate).cloneNode(true);
    tmp._currentLine = tmp_0 instanceof Element ? tmp_0 : THROW_CCE();
    var tmp_1 = $this;
    var tmp0_get_0 = ensureNotNull($this._currentLine).getElementsByClassName('body');
    var tmp1_get_0 = 0;
    tmp_1._currentBody = tmp0_get_0[tmp1_get_0];
    var tmp0_elvis_lhs = content;
    setLine($this, tmp0_elvis_lhs == null ? getLine($this) : tmp0_elvis_lhs);
    (function () {
      var $externalVarargReceiverTmp = ensureNotNull($this._console);
      return $externalVarargReceiverTmp.append.apply($externalVarargReceiverTmp, [].concat([$this._currentLine]));
    }.call(this));
  }
  function newPrompt$default($this, content, $mask0, $handler) {
    if (!(($mask0 & 2) === 0))
      content = null;
    return newPrompt($this, content);
  }
  function setupStdout($this) {
    var tmp0_div_0 = _get_create_(document);
    var tmp1_div_0 = null;
    var tmp0_visitAndFinalize_0_2 = new DIV(attributesMapOf('class', tmp1_div_0), tmp0_div_0);
    var tmp2_apply_0 = visitTagAndFinalize(tmp0_visitAndFinalize_0_2, tmp0_div_0, _no_name_provided_$factory_33());
    (function () {
      var $externalVarargReceiverTmp = tmp2_apply_0.classList;
      return $externalVarargReceiverTmp.add.apply($externalVarargReceiverTmp, [].concat(['stdout']));
    }.call(this));
    var stdout = tmp2_apply_0;
    (function () {
      var $externalVarargReceiverTmp = ensureNotNull($this._console);
      return $externalVarargReceiverTmp.append.apply($externalVarargReceiverTmp, [].concat([stdout]));
    }.call(this));
    return stdout;
  }
  function complete($this, input) {
    var prefix = '';
    var choices = emptyList();
    var tmp0_container = parse($this, input);
    var cmd = tmp0_container.component1();
    var argv = tmp0_container.component2();
    var command = commands.get_20(cmd);
    var tmp;
    if (!(command == null)) {
      var tmp0_isNotEmpty_0 = command.complete_3(argv);
      tmp = !tmp0_isNotEmpty_0.isEmpty_28();
    } else {
      tmp = false;
    }
    if (tmp) {
      prefix = '' + cmd + ' ';
      choices = command.complete_3(argv);
    } else {
      if (argv.isEmpty_28()) {
        var tmp1_safe_receiver = commands.get_20('help');
        choices = ensureNotNull(tmp1_safe_receiver == null ? null : tmp1_safe_receiver.complete_3(listOf_0(cmd)));
      } else {
      }
    }
    if (choices._get_size__32() === 1) {
      setLine($this, prefix + first(choices));
    } else if (choices._get_size__32() > 1) {
      removeCurrentLineCursor($this);
      var stdout = setupStdout($this);
      var tmp1_forEachIndexed_0 = choices;
      var index_1 = 0;
      var tmp0_iterator_2 = tmp1_forEachIndexed_0.iterator_40();
      while (tmp0_iterator_2.hasNext_14()) {
        var item_3 = tmp0_iterator_2.next_16();
        var tmp1_4 = index_1;
        index_1 = tmp1_4 + 1 | 0;
        var tmp2__anonymous__5 = checkIndexOverflow(tmp1_4);
        if (!(tmp2__anonymous__5 === 0)) {
          var tmp0_this_6 = stdout;
          tmp0_this_6.innerHTML = tmp0_this_6.innerHTML + '<br/>';
        }var tmp1_this_7 = stdout;
        tmp1_this_7.innerHTML = tmp1_this_7.innerHTML + item_3;
      }
      newPrompt($this, getLine($this));
    }}
  function exec($this, input, silent) {
    $this._tmpCmd = null;
    $this._historyOffset = null;
    removeCurrentLineCursor($this);
    var line = toString_1(trim(isCharSequence(input) ? input : THROW_CCE()));
    if (charSequenceLength(line) > 0) {
      if (!silent ? !$this._history.contains_19(line) : false) {
        $this._history.add_16(line);
        Unit_getInstance();
      }var stdout = setupStdout($this);
      var print = _no_name_provided_$factory_34(stdout);
      var tmp0_container = parse($this, input);
      var cmd = tmp0_container.component1();
      var argv = tmp0_container.component2();
      var tmp1_safe_receiver = commands.get_20(cmd);
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        tmp1_safe_receiver.exec_4(argv, print);
        tmp = Unit_getInstance();
      }
      var tmp2_elvis_lhs = tmp;
      if (tmp2_elvis_lhs == null)
        print('' + 'command not found: ' + cmd);
      else {
        Unit_getInstance();
      }
    } else {
    }
    newPrompt$default($this, null, 2, null);
  }
  function exec$default($this, input, silent, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      silent = false;
    return exec($this, input, silent);
  }
  function Help() {
    Help_instance = this;
    this._help_2 = 'this command';
  }
  Help.prototype._get_help__3 = function () {
    return this._help_2;
  };
  Help.prototype.exec_4 = function (argv, print) {
    var cmds = toList(commands._get_keys__7());
    var inductionVariable = 0;
    var last_1 = cmds._get_size__32() - 1 | 0;
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (i > 0) {
          print('<br/>');
        }print('' + cmds.get_30(i) + ': ' + ensureNotNull(commands.get_20(cmds.get_30(i)))._get_help__3());
      }
       while (inductionVariable <= last_1);
  };
  Help.prototype.complete_3 = function (argv) {
    var choices = ArrayList_init_$Create$();
    var inductionVariable = 0;
    var last_1 = commands._get_keys__7()._get_size__32();
    if (inductionVariable <= last_1)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp;
        var tmp_0 = Console_getInstance()._history.get_30(i);
        var tmp_1 = argv.get_30(0);
        if (indexOf$default(tmp_0, tmp_1, 0, false, 6, null) === 0) {
          tmp = true;
        } else {
          {
            var tmp_2 = Console_getInstance()._history.get_30(i);
            tmp = indexOf$default(tmp_2, ' ', 0, false, 6, null) === 0;
          }
        }
        if (tmp) {
          choices.add_16(Console_getInstance()._history.get_30(i));
          Unit_getInstance();
        } else {
        }
      }
       while (!(i === last_1));
    return choices;
  };
  Help.$metadata$ = {
    simpleName: 'Help',
    kind: 'object',
    interfaces: [Command]
  };
  var Help_instance;
  function Help_getInstance() {
    if (Help_instance == null)
      new Help();
    return Help_instance;
  }
  function _no_name_provided__49() {
  }
  _no_name_provided__49.prototype.invoke_81 = function ($this$visitTagAndFinalize) {
    Unit_getInstance();
  };
  _no_name_provided__49.prototype.invoke_84 = function (p1) {
    this.invoke_81(p1 instanceof DIV ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__49.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__50($stdout) {
    this._$stdout = $stdout;
  }
  _no_name_provided__50.prototype.invoke_67 = function (it) {
    var tmp0_this = this._$stdout;
    tmp0_this.innerHTML = tmp0_this.innerHTML + it;
    scrollDown(Console_getInstance());
  };
  _no_name_provided__50.prototype.invoke_84 = function (p1) {
    this.invoke_67((!(p1 == null) ? typeof p1 === 'string' : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__50.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Console_0() {
    Console_instance = this;
    this._preventDefault = true;
    this._console = null;
    this._currentLine = null;
    this._currentBody = null;
    this._historyOffset = null;
    this._consoleLineTemplate = null;
    this._tmpCmd = null;
    var tmp = this;
    tmp._history = ArrayList_init_$Create$();
  }
  Console_0.prototype.init = function () {
    this._console = document.getElementById('console');
    var tmp = this;
    var tmp0_get_0 = document.getElementsByClassName('console-line');
    var tmp1_get_0 = 1;
    tmp._currentLine = tmp0_get_0[tmp1_get_0];
    var tmp_0 = this;
    var tmp2_get_0 = ensureNotNull(this._currentLine).getElementsByClassName('body');
    var tmp3_get_0 = 0;
    tmp_0._currentBody = tmp2_get_0[tmp3_get_0];
    this._consoleLineTemplate = ensureNotNull(this._currentLine).cloneNode(true);
  };
  Console_0.prototype.clear_0 = function () {
    while (!(ensureNotNull(this._console).lastChild == null)) {
      ensureNotNull(this._console).removeChild(ensureNotNull(ensureNotNull(this._console).lastChild));
      Unit_getInstance();
    }
  };
  Console_0.prototype.backspace = function () {
    return setLine(this, dropLast(getLine(this), 1));
  };
  Console_0.prototype.tab = function () {
    return complete(this, getLine(this));
  };
  Console_0.prototype.enter = function () {
    var tmp = getLine(this);
    return exec$default(this, tmp, false, 4, null);
  };
  Console_0.prototype.up = function () {
    var tmp0_isNotEmpty_0 = this._history;
    if (!tmp0_isNotEmpty_0.isEmpty_28()) {
      if (this._historyOffset == null) {
        this._tmpCmd = getLine(this);
        this._historyOffset = this._history._get_size__32();
      }var tmp = this;
      var tmp1_max_0 = 0;
      var tmp2_max_0 = ensureNotNull(this._historyOffset) - 1 | 0;
      tmp._historyOffset = function () {
        var $externalVarargReceiverTmp = Math;
        return $externalVarargReceiverTmp.max.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp1_max_0, tmp2_max_0]))));
      }.call(this);
      setLine(this, this._history.get_30(ensureNotNull(this._historyOffset)));
    } else {
    }
  };
  Console_0.prototype.down = function () {
    if (!(this._historyOffset == null) ? ensureNotNull(this._historyOffset) < (this._history._get_size__32() - 1 | 0) : false) {
      var tmp = this;
      var tmp0_min_0 = this._history._get_size__32();
      var tmp1_min_0 = ensureNotNull(this._historyOffset) + 1 | 0;
      tmp._historyOffset = function () {
        var $externalVarargReceiverTmp = Math;
        return $externalVarargReceiverTmp.min.apply($externalVarargReceiverTmp, [].concat([].slice.call(new Int32Array([tmp0_min_0, tmp1_min_0]))));
      }.call(this);
      setLine(this, this._history.get_30(ensureNotNull(this._historyOffset)));
    }};
  Console_0.prototype.input_0 = function (event) {
    if (((event.key.length === 1 ? !event.ctrlKey : false) ? !event.altKey : false) ? !event.metaKey : false) {
      setLine(this, getLine(this) + event.key);
    } else {
      this._preventDefault = false;
    }
  };
  Console_0.$metadata$ = {
    simpleName: 'Console',
    kind: 'object',
    interfaces: []
  };
  var Console_instance;
  function Console_getInstance() {
    if (Console_instance == null)
      new Console_0();
    return Console_instance;
  }
  function _no_name_provided_$factory_33() {
    var i = new _no_name_provided__49();
    return function (p1) {
      i.invoke_81(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_34($stdout) {
    var i = new _no_name_provided__50($stdout);
    return function (p1) {
      i.invoke_67(p1);
      return Unit_getInstance();
    };
  }
  var files;
  function File_0(name, content) {
    this._name = name;
    this._content = content;
  }
  File_0.prototype.toString = function () {
    return '' + 'File(name=' + this._name + ', content=' + this._content + ')';
  };
  File_0.prototype.hashCode = function () {
    var result = getStringHashCode(this._name);
    result = imul(result, 31) + getStringHashCode(this._content) | 0;
    return result;
  };
  File_0.prototype.equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof File_0))
      return false;
    else {
    }
    var tmp0_other_with_cast = other instanceof File_0 ? other : THROW_CCE();
    if (!(this._name === tmp0_other_with_cast._name))
      return false;
    if (!(this._content === tmp0_other_with_cast._content))
      return false;
    return true;
  };
  File_0.$metadata$ = {
    simpleName: 'File',
    kind: 'class',
    interfaces: []
  };
  function main() {
    var tmp0_div_0 = _get_append_(ensureNotNull(document.body));
    var tmp1_div_0 = null;
    var tmp0_visitAndFinalize_0_2 = new DIV(attributesMapOf('class', tmp1_div_0), tmp0_div_0);
    visitTagAndFinalize(tmp0_visitAndFinalize_0_2, tmp0_div_0, _no_name_provided_$factory_35());
    Unit_getInstance();
    Console_getInstance().init();
    var tmp = window;
    tmp.onkeydown = _no_name_provided_$factory_36();
  }
  function _no_name_provided__51() {
  }
  _no_name_provided__51.prototype.invoke_69 = function ($this$unsafe) {
    $this$unsafe.unaryPlus_2('<p>\uD83D\uDC4B Hi, I\'m <b>Mikhail Chigrin<\/b> (<a href="https://t.me/frozrt" target="_blank">@chigr.in<\/a>), an\n                    engineer passionate about Computer Science. <br><br>\n\n                    <a href="https://www.linkedin.com/in/mikhail-chigrin" target="_blank">LinkedIn<\/a>\n                    \xB7 <a href="https://github.com/FrozRt" target="_blank">GitHub<\/a><br><br>\n\n                    I seek opportunities to solve problems in many domains and create great products.<br><br>\n                    Now I\'m working as a Lead Software Engineer at <a href="https://www.epam.com/" target="_blank">EPAM systems<\/a>.<br><br>\n                    Are you interested in collaboration? Get in touch with me through the attached links above.<br><\/p>');
  };
  _no_name_provided__51.prototype.invoke_84 = function (p1) {
    this.invoke_69((!(p1 == null) ? isInterface(p1, Unsafe) : false) ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__51.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__52() {
  }
  _no_name_provided__52.prototype.invoke_71 = function ($this$visitTag) {
    $this$visitTag.unaryPlus_2('chigr.in \u203A ');
  };
  _no_name_provided__52.prototype.invoke_84 = function (p1) {
    this.invoke_71(p1 instanceof PRE ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__52.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__53() {
  }
  _no_name_provided__53.prototype.invoke_81 = function ($this$visitTag) {
    var tmp0_pre_0_5_8_15 = null;
    var tmp0_visit_0_2_6_9_16 = new PRE(attributesMapOf('class', tmp0_pre_0_5_8_15), $this$visitTag._get_consumer__3());
    visitTag(tmp0_visit_0_2_6_9_16, _no_name_provided_$factory_42());
  };
  _no_name_provided__53.prototype.invoke_84 = function (p1) {
    this.invoke_81(p1 instanceof DIV ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__53.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__54() {
  }
  _no_name_provided__54.prototype.invoke_81 = function ($this$visitTag) {
    Unit_getInstance();
  };
  _no_name_provided__54.prototype.invoke_84 = function (p1) {
    this.invoke_81(p1 instanceof DIV ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__54.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__55() {
  }
  _no_name_provided__55.prototype.invoke_81 = function ($this$visitTag) {
    unsafe($this$visitTag, _no_name_provided_$factory_39());
  };
  _no_name_provided__55.prototype.invoke_84 = function (p1) {
    this.invoke_81(p1 instanceof DIV ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__55.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__56() {
  }
  _no_name_provided__56.prototype.invoke_81 = function ($this$visitTag) {
    var tmp0_div_0_5_12 = 'prompt';
    var tmp0_visit_0_2_6_13 = new DIV(attributesMapOf('class', tmp0_div_0_5_12), $this$visitTag._get_consumer__3());
    visitTag(tmp0_visit_0_2_6_13, _no_name_provided_$factory_40());
    var tmp1_div_0_11_18 = 'body';
    var tmp0_visit_0_2_12_19 = new DIV(attributesMapOf('class', tmp1_div_0_11_18), $this$visitTag._get_consumer__3());
    visitTag(tmp0_visit_0_2_12_19, _no_name_provided_$factory_41());
  };
  _no_name_provided__56.prototype.invoke_84 = function (p1) {
    this.invoke_81(p1 instanceof DIV ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__56.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__57() {
  }
  _no_name_provided__57.prototype.invoke_81 = function ($this$visitTagAndFinalize) {
    _set_id_($this$visitTagAndFinalize, 'console');
    var tmp0_div_0_5 = 'console-line';
    var tmp0_visit_0_2_6 = new DIV(attributesMapOf('class', tmp0_div_0_5), $this$visitTagAndFinalize._get_consumer__3());
    visitTag(tmp0_visit_0_2_6, _no_name_provided_$factory_37());
    var tmp1_div_0_9 = 'console-line active';
    var tmp0_visit_0_2_10 = new DIV(attributesMapOf('class', tmp1_div_0_9), $this$visitTagAndFinalize._get_consumer__3());
    visitTag(tmp0_visit_0_2_10, _no_name_provided_$factory_38());
  };
  _no_name_provided__57.prototype.invoke_84 = function (p1) {
    this.invoke_81(p1 instanceof DIV ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__57.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__58() {
  }
  _no_name_provided__58.prototype.invoke_83 = function (it) {
    var tmp0_subject = it.key;
    switch (tmp0_subject) {
      case 'Backspace':
        Console_getInstance().backspace();
        break;
      case 'Tab':
        Console_getInstance().tab();
        break;
      case 'Enter':
        Console_getInstance().enter();
        break;
      case 'ArrowUp':
        Console_getInstance().up();
        break;
      case 'ArrowDown':
        Console_getInstance().down();
        break;
      default:Console_getInstance().input_0(it);
        break;
    }
    if (Console_getInstance()._preventDefault) {
      it.preventDefault();
    }};
  _no_name_provided__58.prototype.invoke_84 = function (p1) {
    this.invoke_83(p1 instanceof KeyboardEvent ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__58.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_35() {
    var i = new _no_name_provided__57();
    return function (p1) {
      i.invoke_81(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_36() {
    var i = new _no_name_provided__58();
    return function (p1) {
      i.invoke_83(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_37() {
    var i = new _no_name_provided__55();
    return function (p1) {
      i.invoke_81(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_38() {
    var i = new _no_name_provided__56();
    return function (p1) {
      i.invoke_81(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_39() {
    var i = new _no_name_provided__51();
    return function (p1) {
      i.invoke_69(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_40() {
    var i = new _no_name_provided__53();
    return function (p1) {
      i.invoke_81(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_41() {
    var i = new _no_name_provided__54();
    return function (p1) {
      i.invoke_81(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_42() {
    var i = new _no_name_provided__52();
    return function (p1) {
      i.invoke_71(p1);
      return Unit_getInstance();
    };
  }
  AbstractMap.prototype._get_entries__7 = Map_0.prototype._get_entries__7;
  IntIterator.prototype.hasNext_14 = Iterator.prototype.hasNext_14;
  AbstractMutableList.prototype.get_30 = List.prototype.get_30;
  AbstractMutableMap.prototype._get_entries__7 = MutableMap.prototype._get_entries__7;
  InternalHashCodeMap.prototype.createJsMap_0 = InternalMap.prototype.createJsMap_0;
  KClassImpl.prototype._get_simpleName__2 = KClass.prototype._get_simpleName__2;
  HTMLTag.prototype.text_3 = Tag.prototype.text_3;
  DIV.prototype.text_3 = Tag.prototype.text_3;
  PRE.prototype.unaryPlus_2 = Tag.prototype.unaryPlus_2;
  PRE.prototype.text_3 = Tag.prototype.text_3;
  JSDOMBuilder.prototype.onTagError_1 = TagConsumer.prototype.onTagError_1;
  functionClasses = functionClasses$init$();
  buf = new ArrayBuffer(8);
  bufFloat64 = bufFloat64$init$();
  bufInt32 = bufInt32$init$();
  lowIndex = lowIndex$init$();
  highIndex = 1 - lowIndex | 0;
  ZERO = fromInt(0);
  ONE = fromInt(1);
  NEG_ONE = fromInt(-1);
  MAX_VALUE = new Long(-1, 2147483647);
  MIN_VALUE = new Long(0, -2147483648);
  TWO_PWR_24_ = fromInt(16777216);
  emptyMap_0 = emptyMap();
  attributeStringString = new StringAttribute();
  commands = commands$init$();
  files = listOf([new File_0('quote.txt', trimIndent('\n                "A human being should be able to change a diaper, plan an invasion, butcher a hog, conn a ship,\n                design a building, write a sonnet, balance accounts, build a wall, set a bone, comfort the dying,\n                take orders, give orders, cooperate, act alone, solve equations, analyze a new problem,\n                pitch manure, program a computer, cook a tasty meal, fight efficiently, die gallantly.\n                Specialization is for insects." \u2015 Robert A. Heinlein.\n    ')), new File_0('hey.html', trimIndent('<p>\uD83D\uDC4B Hi, I\'m <b>Mikhail Chigrin<\/b> (<a href="https://t.me/frozrt">@chigr.in<\/a>), an\n                    engineer passionate about Computer Science. <br><br>\n\n                    <a href="https://www.linkedin.com/in/mikhail-chigrin" target="_blank">LinkedIn<\/a>\n                    \xB7 <a href="https://github.com/FrozRt" target="_blank">GitHub<\/a><br><br>\n\n                    I seek opportunities to solve problems in many domains and create great products.<br><br>\n                    Now I\'m working as a Lead Software Engineer at <a href="https://www.epam.com/" target="_blank">EPAM systems<\/a>.<br><br>\n                    Are you interested in collaboration?<br><br>\n                    Get in touch with me through the attached links above.<br><\/p>'))]);
  main();
  return _;
}));
